import React, { Component } from 'react';
import Sidemenu from '../components/menu/menu';
import Specialities from './specialities/specialities';
import Header from '../components/header/header';
import Doctor from './doctors/doctors';
import { connect } from 'react-redux';

import Hospital from './hospitals/hospital';

import SpecialityService from '../../services/speciality.service';
import UserService from '../../services/user.service';
import ServiceCategoriesService from '../../services/service_categories.service';
import ServicesService from '../../services/services.service';
import CountriesService from '../../services/country';

import Banners from './banners/banners';
import Business from './business/business';
import Service_tabs from './service_categories/service_tabs';
import Countries from './countries/countries';

class Pages extends Component {
  constructor(props) {
    super(props);
    this.getSpecialities = this.getSpecialities.bind(this);
  }
  async getSpecialities() {
    var spec = [];
    spec = await SpecialityService.GetSpecialityes('-1');
    this.props.setSpecialitis(spec.data.specialitys);
  }

  async getService_categories() {
    try {
      let service_categories = await ServiceCategoriesService.GetService_categories();
      this.props.setServiceCategories(service_categories.data);
    } catch (err) {
      console.log(err);
    }
  }
  async getServices() {
    try {
      let services = await ServicesService.GetServices();
      this.props.setServices(services.data);
    } catch (err) {
      console.log(err);
    }
  }
  async getCountries() {
    try {
      let countries = await CountriesService.GetCountry();
      this.props.setCountries(countries.data);
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    UserService.StatusApi()
      .then((data) => {
        this.getSpecialities();
        this.getService_categories();
        this.getServices();
        this.getCountries();
      })
      .catch((err) => {
        localStorage.clear();
        window.location.reload();
      });
  }
  render() {
    return (
      <>
        <Sidemenu />
        <Header />
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <div className="main-body">
                  <div className="page-wrapper">
                    {this.props.page === "doctors" ? <Doctor /> : null}
                    {this.props.page === "hospitals" ? <Hospital /> : null}
                    {this.props.page === "speciality" ? <Specialities getSpecialities={this.getSpecialities} /> : null}
                    {this.props.page === "banners" ? <Banners /> : null}
                    {this.props.page === "business" ? <Business /> : null}
                    {this.props.page === "service_categories" ? <Service_tabs /> : null}
                    {this.props.page === "countries" ? <Countries /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  page: state.page,
});
const mapDispatchToProps = (dispatch) => ({
  setSpecialitis(value) {
    dispatch({
      type: 'CHANGE_SPECIALITY',
      value: value,
    });
  },
  //New
  setServiceCategories(value) {
    dispatch({
      type: 'FETCH_SERVICE_CATEGORIES',
      value: value,
    });
  },
  setServices(value) {
    dispatch({
      type: 'FETCH_SERVICES',
      value: value,
    });
  },
  setCountries(value) {
    dispatch({
      type: 'FETCH_COUNTRIES',
      value: value,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
