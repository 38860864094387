import React, { Component } from 'react';
import Pages from './app/pages/page';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Login from './app/pages/login/login';
import { Provider } from 'react-redux';
import './app.css'
import store from './store';
import "bootstrap/dist/css/bootstrap.min.css"

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<div className="App">
					<Router>
						{/* <div className="loader-bg">
						<div className="loader-track">
							<div className="loader-fill"></div>
						</div>
					</div> */}
						{localStorage.getItem('token') ? (
							<Route exact path="/guia" component={Pages} />
						) : (
							<Redirect from="/guia" to="/login" />
						)}
						{localStorage.getItem('token') ? (
							<Redirect from="/login" to="/guia" />
						) : (
							<Route exact path="/login" component={Login} />
						)}
					</Router>
					<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
					<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
					<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>
				</div>
			</Provider>
		);
	}
}

export default App;
