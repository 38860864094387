import React, { Component } from 'react';
import DoctorService from '../../../services/doctor.service';

import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';

import Swal from 'sweetalert2';
class NormalDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: [],
      activePage: 1,
      perpage: 12,
      indexLast: 11,
      indexInitial: 0,

      specialityFilter: '',
      namefilter: '',
    };
    this.changeSpecialityFilter = this.changeSpecialityFilter.bind(this);
    this.changeNameFilter = this.changeNameFilter.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.isPremium = this.isPremium.bind(this);
    this.setAttendEmergencies = this.setAttendEmergencies.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.hospital !== this.props.hospital ||
      prevProps.city !== this.props.city ||
      (prevProps.action !== this.props.action && this.props.action)
    ) {
      this.getDoctors(this.props.city, this.props.hospital);
    }
  }
  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      indexLast: pageNumber * this.state.perpage - 1,
      indexInitial: (pageNumber - 1) * this.state.perpage,
    });
  }
  changeSpecialityFilter(e) {
    this.setState({ specialityFilter: e.target.value });
  }
  changeNameFilter(e) {
    this.setState({
      namefilter: e.target.value,
      activePage: 1,
      indexLast: this.state.perpage - 1,
      indexInitial: 0 * this.state.perpage,
    });
  }
  async getDoctors(city, hospital) {
    var doctors = await DoctorService.ListAllDoctors(-1, city, hospital);
    this.setState({ doctors: doctors.doctors, activePage: 1 });
  }
  async changeStatus(id) {
    var doctor = await DoctorService.changeStatus(id);
    if (doctor) {
      this.getDoctors(this.props.city, this.props.hospital);
      this.props.changeEvent({ action: Math.random() * (100 - 50) + 50 });

      Swal.fire({
        type: 'success',
        title: 'Doctor ocultado exitosamente',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  isPremium(id) {
    if (this.props.ids_premium.indexOf(id) >= 0) return true;
    else return false;
  }
  addPremiumDoctor(id) {
    var ids = this.props.ids_premium;
    if (ids.indexOf(id) >= 0) {
      ids.splice(ids.indexOf(id), 1);
      Swal.fire({
        type: 'success',
        title: 'Doctor eliminado de la lista premium',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      ids.push(id);
      Swal.fire({
        type: 'success',
        title: 'Doctor agregado a premium',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    this.updatePremiumDoctor(ids);
  }
  async updatePremiumDoctor(ids) {
    var premium = await DoctorService.UpdateDoctorsPremium(ids);
    if (premium.data.doctors.length > 0) {
      this.props.changeEvent({ action: Math.random() * (50 - 0) });
      this.props.setPremium(premium.data.doctors);
    }
  }

  async setAttendEmergencies(doctor) {
    let emergenciesObj = { attend_emergencies: !doctor.attend_emergencies };

    try {
      let doctor_updated = await DoctorService.UpdateAttendEmergenciesDoctor(emergenciesObj, doctor._id);
      if (doctor_updated) {
        console.log(doctor_updated.data.attend_emergencies);
        if (doctor_updated.data.attend_emergencies === true) {
          Swal.fire({
            type: 'success',
            title: 'Doctor agregado a emergencias',
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            type: 'success',
            title: 'Doctor removido de emergencias',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'Intente más tarde',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'Error de conexión, intente más tarde',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  render() {
    return (
      <div className="card Recent-Users">
        <div className="card-block px-0 py-3">
          <div className="filterforms">
            <div className="form-group">
              <select className="form-control" id="selectspeciality" onChange={this.changeSpecialityFilter} value={this.state.specialityFilter}>
                <option value="">Mostrar todas las especialidades</option>
                {this.props.specialities.map((speciality, index) => (
                  <option value={speciality._id} key={speciality._id}>
                    {speciality.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <input value={this.state.namefilter} onChange={this.changeNameFilter} className="form-control" placeholder="Buscar"></input>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: '60px' }}>Premium</th>
                  <th style={{ width: '60px' }}>Emergencias</th>
                  <th></th>
                  <th>Nombre y especialidades</th>
                  <th>Centro médico</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {this.state.doctors
                  .filter(
                    (a) =>
                      a.speciality.findIndex((item) => {
                        if (this.state.specialityFilter !== '' && item) {
                          return item._id === this.state.specialityFilter;
                        } else {
                          return item;
                        }
                      }) >= 0
                  )
                  .filter((a) => {
                    let name = a.user.firstname.toLowerCase() + ' ' + a.user.lastname.toLowerCase();
                    return (
                      this.state.namefilter === '' ||
                      name
                        .normalize('NFD')
                        .replace(/\p{Diacritic}/gu, '')
                        .includes(
                          this.state.namefilter
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/\p{Diacritic}/gu, '')
                        )
                    );
                  })
                  .filter(
                    (a) =>
                      a.consulting_room.findIndex((item) => {
                        if (item.hospital !== null && this.props.hospital !== '') {
                          return item.hospital._id === this.props.hospital;
                        } else {
                          return item;
                        }
                      }) >= 0
                  )
                  .map((data, index) => {
                    return this.state.indexInitial <= index && index <= this.state.indexLast ? (
                      <tr className="d-flex" key={data._id} className="unread">
                        <td
                          className="text-center"
                          onClick={async () => {
                            await this.addPremiumDoctor(data._id);
                            await this.getDoctors(this.props.city, this.props.hospital);
                          }}
                        >
                          {!this.isPremium(data._id) ? (
                            <i className="feather icon-star" style={{ margin: '15px auto' }}></i>
                          ) : (
                            <i className="feather icon-star-on" style={{ color: '#219148', margin: '15px auto' }}></i>
                          )}
                        </td>
                        <td
                          className="text-center"
                          onClick={async () => {
                            await this.setAttendEmergencies(data);
                            await this.getDoctors(this.props.city, this.props.hospital);
                          }}
                        >
                          {data.attend_emergencies === false || data.attend_emergencies === undefined ? (
                            <i class="far fa-check-square" style={{ margin: '15px auto' }}></i>
                          ) : (
                            <i class="fas fa-check-square" style={{ color: '#219148', margin: '15px auto' }}></i>
                          )}
                        </td>
                        <td style={{ width: '50px' }}>
                          <img className="rounded-circle" style={{ width: '50px', height: '50px' }} src={data.user.profilepic} alt="activity-user" />
                        </td>
                        <td>
                          <h6 className="mb-1">
                            {data.user.firstname} {data.user.lastname}
                          </h6>
                          <p className="m-0">
                            {data.speciality.map((s, is) => (
                              <span key={is}>
                                {is > 0 ? ', ' : null}
                                {s.name}
                              </span>
                            ))}
                          </p>
                        </td>
                        <td>
                          <h6 className="text-muted">
                            {data.consulting_room.map((h, ih) => (
                              <>
                                {ih > 0 ? ', ' : null}
                                {h.hospital ? h.hospital.name : null}
                              </>
                            ))}
                          </h6>
                        </td>
                        <td style={{ width: '60px', cursor: 'pointer' }}>
                          <i onClick={() => this.props.changeEvent({ update: data })} className="feather icon-edit-2"></i>
                        </td>
                        <td style={{ width: '60px', cursor: 'pointer' }}>
                          <i className="feather icon-eye-off" onClick={() => this.changeStatus(data._id)}></i>
                        </td>
                      </tr>
                    ) : null;
                  })}
              </tbody>
            </table>

            <Pagination
              hideFirstLastPages
              pageRangeDisplayed={this.state.perpage}
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.perpage}
              totalItemsCount={
                this.state.doctors
                  .filter(
                    (a) =>
                      a.speciality.findIndex((item) => {
                        if (this.state.specialityFilter !== '') {
                          return item._id === this.state.specialityFilter;
                        } else {
                          return item;
                        }
                      }) >= 0
                  )
                  .filter(
                    (a) =>
                      a.consulting_room.findIndex((item) => {
                        if (item.hospital !== null && this.props.hospital !== '') {
                          return item.hospital._id === this.props.hospital;
                        } else {
                          return item;
                        }
                      }) >= 0
                  )
                  .filter((a) => this.state.namefilter === '' || a.user.firstname.toLowerCase().includes(this.state.namefilter.toLowerCase())).length
              }
              onChange={(pageNumber) => this.handlePageChange(pageNumber)}
            />

            {this.state.doctors
              .filter(
                (a) =>
                  a.speciality.findIndex((item) => {
                    if (this.state.specialityFilter !== '') {
                      return item._id === this.state.specialityFilter;
                    } else {
                      return item;
                    }
                  }) >= 0
              )
              .filter(
                (a) =>
                  a.consulting_room.findIndex((item) => {
                    if (item.hospital !== null && this.props.hospital !== '') {
                      return item.hospital._id === this.props.hospital;
                    } else {
                      return item;
                    }
                  }) >= 0
              )
              .filter((a) => this.state.namefilter === '' || a.user.firstname.toLowerCase().includes(this.state.namefilter.toLowerCase())).length <=
            0 ? (
              <p style={{ textAlign: 'center' }}>No hay doctores registrados</p>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialities: state.specialities,
  ids_premium: state.ids_premium,
});
const mapDispatchToProps = (dispatch) => ({
  setPremium(ids_premium) {
    dispatch({
      type: 'SET_TO_PREMIUM',
      ids_premium: ids_premium,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NormalDoctor);
