import React, { Component } from "react";
import Country from "../../../services/country";
import { connect } from "react-redux";
import { Tabs } from "antd";
import Businessform from "./businessform";
import Businesstable from "./businesstable";
const { TabPane } = Tabs;

class Business extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: [],
      city: "Puerto Ordaz",
      activeState: "Bolívar",
      index: 0,
      show: false,
      type: "1",
      update: null,
    };
  }
  componentDidMount = () => {
    Country.GetCountry().then((response) => {
      this.setState({ country: response.data });
      this.setState({ event: Math.round() * 100 });
    });
  };
  changeState = (event) => {
    this.setState({
      activeState: event.target.value,
      city: this.state.country.filter((state) => state.state === event.target.value)[0].city[0].city,
      index: event.target.value,
    });
  };
  changeCity = (event) => {
    this.setState({ city: event.target.value, indexLast: 5, indexInitial: 0 });
  };

  closeForm = (value) => {
    this.setState({ show: value, update: null });
  };
  openUpdate = (data) => {
    this.setState({ show: true, update: data });
  };
  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "baseline",
          }}
        >
          <button className="btn btn-primary" onClick={() => this.setState({ show: true })}>

            Añadir consultorio
          </button>

          <div className="form-group">
            <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeState} value={this.state.activeState}>
              {this.state.country
                .filter((state) => state.active === true)
                .map((state, index) => (
                  <option value={state.state} key={state._id}>
                    {state.state}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group" style={{ marginLeft: "20px" }}>
            <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeCity} value={this.state.city}>
              <option value="all">Todas las ciudades</option>
              {this.state.country.length > 0
                ? this.state.country
                    .filter((state) => state.state === this.state.activeState)[0]
                    .city.filter((city) => city.active === true)
                    .map((city, index) => (
                      <option value={city.city} key={city.city._id}>
                        {city.city}
                      </option>
                    ))
                : null}
            </select>
          </div>
        </div>
        {this.state.show ? (
          <Businessform
            country={this.state.country}
            update={this.state.update}
            onClose={(e) => this.closeForm(e)}
          />
        ) : null}
        <Tabs defaultActiveKey="1" onChange={(e) => this.setState({ type: e })}>
          <TabPane tab={"Consultorio odontológico"} key="1">
            <Businesstable
              city={this.state.city}
              type={this.state.type}
              show={this.state.show}
              onUpdate={(e) => this.openUpdate(e)}
            />
          </TabPane>
          <TabPane tab="Consultorio oftalmológico" key="2">
            <Businesstable
              city={this.state.city}
              type={this.state.type}
              show={this.state.show}
              onUpdate={(e) => this.openUpdate(e)}
            />
          </TabPane>
          <TabPane tab="Laboratorios" key="3">
            <Businesstable
              city={this.state.city}
              type={this.state.type}
              show={this.state.show}
              onUpdate={(e) => this.openUpdate(e)}
            />
          </TabPane>
          <TabPane tab="Spa" key="4">
            <Businesstable
              city={this.state.city}
              type={this.state.type}
              show={this.state.show}
              onUpdate={(e) => this.openUpdate(e)}
            />
          </TabPane>
          <TabPane tab="Farmacias" key="5">
            <Businesstable
              city={this.state.city}
              type={this.state.type}
              show={this.state.show}
              onUpdate={(e) => this.openUpdate(e)}
            />
          </TabPane>
        </Tabs>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Business);
