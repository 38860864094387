import React, { Component } from 'react';
import DoctorService from '../../../services/doctor.service';

import Pagination from 'react-js-pagination';

import Swal from 'sweetalert2';
class DisableDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: [],

      activePage: 1,
      perpage: 6,
      indexLast: 5,
      indexInitial: 0
    };
  }
  componentDidMount() {
    this.getDoctors(this.props.city, this.props.hospital);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.hospital !== this.props.hospital ||
      prevProps.city !== this.props.city ||
      (prevProps.action !== this.props.action && this.props.action)
    ) {
      this.getDoctors(this.props.city, this.props.hospital);
    }
  }

  async getDoctors(city, hospital) {
    var doctors = await DoctorService.ListAllInactiveDoctors(
      -1,
      city,
      hospital
    );
    this.setState({ doctors: doctors.doctors });
  }
  async changeStatus(id) {
    var doctor = await DoctorService.changeStatus(id);
    this.getDoctors(this.props.city, this.props.hospital);
    this.props.changeEvent({ action: Math.random() * 100 });
    Swal.fire({
      type: 'success',
      title: 'Doctor habilitado exitosamente',
      showConfirmButton: false,
      timer: 1500
    });
  }
  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      indexLast: pageNumber * this.state.perpage - 1,
      indexInitial: (pageNumber - 1) * this.state.perpage
    });
  }
  render() {
    return (
      <div className="card Recent-Users">
        <div className="card-block px-0 py-3">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Nombre y especialidades</th>
                  <th>Centro médico</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {this.state.doctors
                  .filter(
                    a =>
                      a.consulting_room.findIndex(item => {
                        if (
                          item.hospital !== null &&
                          this.props.hospital !== ''
                        ) {
                          return item.hospital._id === this.props.hospital;
                        } else {
                          return item;
                        }
                      }) >= 0
                  )
                  .map((data, index) =>
                    this.state.indexInitial <= index &&
                    index <= this.state.indexLast ? (
                      <tr key={data._id} className="unread">
                        {/* <td onClick={() => this.addPremiumDoctor(data._id)}>
															{!this.isPremium(data._id) ? (
																<i className="feather icon-star"></i>
															) : (
																	<i className="feather icon-star-on" style={{ color: "#219148" }}></i>
																)}
														</td> */}
                        <td style={{ width: '50px' }}>
                          <img
                            className="rounded-circle"
                            style={{ width: '50px', height: '50px' }}
                            src={data.user.profilepic}
                            alt="activity-user"
                          />
                        </td>
                        <td>
                          <h6 className="mb-1">
                            {data.user.firstname} {data.user.lastname}
                          </h6>
                          <p className="m-0">
                            {data.speciality.map((s, is) => (
                              <>
                                {is > 0 ? ', ' : null}
                                {s.name}
                              </>
                            ))}
                          </p>
                        </td>
                        <td>
                          <h6 className="text-muted">
                            {data.consulting_room.map((h, ih) => (
                              <>
                                {ih > 0 ? ', ' : null}
                                {h.hospital ? h.hospital.name : null}
                              </>
                            ))}
                          </h6>
                        </td>
                        <td style={{ width: '60px', cursor: 'pointer' }}>
                          <i
                            className="feather icon-eye"
                            onClick={() => this.changeStatus(data._id)}
                          ></i>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            </table>
            <Pagination
              hideFirstLastPages
              pageRangeDisplayed={this.state.perpage}
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.perpage}
              totalItemsCount={
                this.state.doctors.filter(
                  a =>
                    a.consulting_room.findIndex(item => {
                      if (
                        item.hospital !== null &&
                        this.props.hospital !== ''
                      ) {
                        return item.hospital._id === this.props.hospital;
                      } else {
                        return item;
                      }
                    }) >= 0
                ).length
              }
              onChange={pageNumber => this.handlePageChange(pageNumber)}
            />
            {this.state.doctors.filter(
              a =>
                a.consulting_room.findIndex(item => {
                  if (item.hospital !== null && this.props.hospital !== '') {
                    return item.hospital._id === this.props.hospital;
                  } else {
                    return item;
                  }
                }) >= 0
            ).length <= 0 ? (
              <p style={{ textAlign: 'center' }}>
                No hay doctores desabilitados
              </p>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default DisableDoctor;
