import React, { Component } from "react";
import BannerService from "../../../services/banner.service";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import global from "../../../services/global";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { Popconfirm } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import userService from "../../../services/user.service";

class Banners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show1: false,
      banner: "",
      content: "",
      social_network: "",
      tlf: "",
      id_to_update: "",
      banners: [],
      contentImg: "",

      activePage: 1,
      perpage: 50,
      indexLast: 49,
      indexInitial: 0,
    };
    this.handleInputFileBannerChange = this.handleInputFileBannerChange.bind(this);
    this.handleInputFileContentChange = this.handleInputFileContentChange.bind(this);
    this.saveBanner = this.saveBanner.bind(this);
    this.close = this.close.bind(this);
    this.deleteBanner = this.deleteBanner.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.reorder = this.reorder.bind(this);
    this.UpdateBannersPosition = this.UpdateBannersPosition.bind(this);
  }

  componentDidMount() {
    this.getBanners();
  }

  async handleInputFileBannerChange(e) {
    const formData = new FormData();
    formData.append("imagen", e.target.files[0]);
    var filesave = await userService.UploadPic(formData);
    if (filesave) {
      this.setState({
        banner: global.URL + "/api/file?file=" + filesave.data.imagen,
      });
    }
  }
  async handleInputFileContentChange(e) {
    const formData = new FormData();
    formData.append("imagen", e.target.files[0]);
    var filesave = await userService.UploadPic(formData);
    if (filesave) {
      this.setState({
        content: global.URL + "/api/file?file=" + filesave.data.imagen,
      });
    }
  }
  async saveBanner() {
    if (this.state.banner !== "" && this.state.content !== "") {
      let doctor = await BannerService.SaveBanners({
        banner: this.state.banner,
        content: this.state.content,
        social_network: this.state.social_network,
        tlf: this.state.tlf,
      });
      if (doctor.data) {
        this.setState({ banner: "", content: "" });
        await this.getBanners();
        Swal.fire({
          type: "success",
          title: "Banner Agregado",
          showConfirmButton: false,
          timer: 1500,
        });
        this.UpdateBannersPosition();
      }
    } else {
      Swal.fire({
        type: "warning",
        title: "Debe agregar ambas imagenes",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async getBanners() {
    var banners = await BannerService.GetBanners();
    this.setState({ banners: banners.data });
  }
  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      indexLast: pageNumber * this.state.perpage - 1,
      indexInitial: (pageNumber - 1) * this.state.perpage,
    });
  }
  close() {
    this.setState({ banner: "", content: "" });
  }
  async deleteBanner(id) {
    var deleteBanner = await BannerService.DeleteBanners(id);
    if (deleteBanner) {
      this.getBanners();
      Swal.fire({
        type: "success",
        title: "Banner eliminado",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  handleClose() {
    this.setState({ show: false, content: "" });
  }
  openModal(content) {
    this.setState({ show: true, contentImg: content });
  }

  async UpdateBannerInfo(data, id) {
    let banner = await BannerService.UpdateBanners(data, id);

    if (banner) {
      Swal.fire({
        type: "success",
        title: "Datos actualizados",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ show1: false, id_to_update: "", tlf: "", social_network: "" });
      this.getBanners();
    }
  }

  async UpdateBannersPosition() {
    let banners = this.state.banners.map(async (banner, index) => {
      return await BannerService.UpdateBannerPostion({ position: index }, banner._id);
    });
    Swal.fire({
      type: "success",
      title: "Orden actualizado",
      showConfirmButton: false,
      timer: 1500,
    });
  }

  async reorder(list, startIndex, endIndex) {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  render() {
    return (
      <>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Imagen contenida</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <img src={this.state.contentImg} alt="profilepic" style={{ width: "60%" }}></img>
          </Modal.Body>
        </Modal>
        <Modal
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          show={this.state.show1}
          onHide={() => this.setState({ show1: !this.state.show1 })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar red social o teléfono</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-6">
                <label>Url de red social</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.social_network}
                  onChange={(e) => {
                    this.setState({ social_network: e.target.value });
                  }}
                />
              </div>
              <div className="col-6">
                <label>Número de teléfono</label>
                <input type="text" className="form-control" value={this.state.tlf} onChange={(e) => this.setState({ tlf: e.target.value })} />
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                className="btn btn-primary"
                style={{ margin: "10px 15px" }}
                onClick={() =>
                  this.UpdateBannerInfo(
                    {
                      social_network: this.state.social_network,
                      tlf: this.state.tlf,
                    },
                    this.state.id_to_update
                  )
                }
              >
                Guardar
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <div className="card" style={{ boxShadow: "0 1px 20px 0 rgba(69, 90, 100, 0.4)" }}>
          <div className="card-block px-0 py-3">
            <div className="container">
              <h3>Crear Banner</h3>
              <div className="row">
                <div className="col-6">
                  <label>Imagen principal</label>
                  <input type="file" className="form-control" onChange={this.handleInputFileBannerChange} />
                  {this.state.banner !== "" || this.state.banner ? (
                    <img src={this.state.banner} alt="profilepic" style={{ width: "20%" }}></img>
                  ) : null}
                </div>
                <div className="col-6">
                  <label>Imagen interna</label>
                  <input type="file" className="form-control" onChange={this.handleInputFileContentChange} />
                  {this.state.content !== "" || this.state.content ? (
                    <img src={this.state.content} alt="profilepic" style={{ width: "20%" }}></img>
                  ) : null}
                </div>
                <div className="col-6">
                  <label>Url de red social</label>
                  <input type="text" className="form-control" onChange={(e) => this.setState({ social_network: e.target.value })} />
                </div>
                <div className="col-6">
                  <label>Número de teléfono</label>
                  <input type="text" className="form-control" onChange={(e) => this.setState({ tlf: e.target.value })} />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "15px 0 ",
            }}
          >
            <button className="btn btn-secundary" style={{ margin: " 0 15px" }} onClick={this.close}>
              Cancelar
            </button>
            <button className="btn btn-primary" style={{ margin: " 0 15px" }} onClick={this.saveBanner}>
              Guardar banner
            </button>
          </div>
        </div>
        <DragDropContext
          onDragEnd={async (result) => {
            const { source, destination } = result;
            if (!destination) {
              return;
            }
            if (source.index === destination.index && source.droppableId === destination.droppableId) {
              return;
            }
            if (source.droppableId === destination.droppableId) {
              const items = await this.reorder(this.state.banners, source.index, destination.index);

              this.setState({
                banners: items,
              });
            }
          }}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div className="row px-3 d-flex flex-column align-items-center" ref={provided.innerRef} {...provided.droppableProps}>
                <div className="col-12">
                  <div className="d-flex flex-row align-items-start justifi-content-start">
                    <button type="button" className="btn btn-primary" onClick={this.UpdateBannersPosition}>
                      Guardar orden
                    </button>
                  </div>
                </div>
                {this.state.banners.map((data, index) =>
                  this.state.indexInitial <= index && index <= this.state.indexLast ? (
                    <Draggable key={data._id} draggableId={data._id} index={index}>
                      {(draggableProvided) => (
                        <div
                          style={{
                            boxShadow: "rgba(69, 90, 100, 0.4) 0px 0px 10px 0px",
                          }}
                          className="card col-3 px-2"
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          ref={draggableProvided.innerRef}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "140px",
                            }}
                            src={data.banner}
                            onClick={() => this.openModal(data.content)}
                            alt="banner_principal"
                          ></img>
                          <div className="my-2" style={{ textAlign: "center" }}>
                            <Popconfirm
                              placement="top"
                              title={"¿Realmente desea eliminar este banner?"}
                              onConfirm={() => this.deleteBanner(data._id)}
                              okText="Si claro"
                              cancelText="No"
                            >
                              <i style={{ cursor: "pointer", fontSize: "14px" }} className="feather icon-trash-2 mx-3"></i>
                            </Popconfirm>
                            <i
                              onClick={() =>
                                this.setState({
                                  show1: !this.state.show1,
                                  id_to_update: data._id,
                                  social_network: data.social_network,
                                  tlf: data.tlf,
                                })
                              }
                              style={{ cursor: "pointer", fontSize: "14px" }}
                              className="feather icon-edit-2 mx-3"
                            ></i>
                            <span className="mx-3">
                              Posición: <strong>{index + 1}</strong>
                            </span>
                          </div>
                          <hr />
                          <div className="mb-2 d-flex flex-column" style={{ textAlign: "left" }}>
                            <span className="mx-3">
                              Red social: <strong>{data.social_network ?? "Sin red social"}</strong>
                            </span>
                            <span className="mx-3">
                              Tlf: <strong>{data.tlf ?? "Sin tlf asociado"}</strong>
                            </span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ) : null
                )}
                {provided.placeholder}
                <div className="col-12">
                  <div className="d-flex flex-row align-items-start justifi-content-start">
                    <button type="button" className="btn btn-primary" onClick={this.UpdateBannersPosition}>
                      Guardar orden
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Pagination
          hideFirstLastPages
          pageRangeDisplayed={this.state.perpage}
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.perpage}
          totalItemsCount={this.state.banners.length}
          onChange={(pageNumber) => this.handlePageChange(pageNumber)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
