import React, { Component, createRef } from 'react';
import ServiceCategoriesService from '../../../services/service_categories.service';
import ServiceService from '../../../services/services.service';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import Pagination from 'react-js-pagination';
import { Popconfirm } from 'antd';
class ServiceCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: '',
      inputRef: createRef(),
      activePage: 1,
      perpage: 6,
      indexLast: 5,
      indexInitial: 0,
      namefilter: '',
      inSave: false,

      categories: [],
      category_name: '',
      category_description: '',
      images: [],
      toUpdate: false,
      idToUpdate: '',
      show: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectToEdit = this.selectToEdit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.changeNameFilter = this.changeNameFilter.bind(this);
    //New
    this.saveServiceCategory = this.saveServiceCategory.bind(this);
    this.UpdateServiceCategory = this.UpdateServiceCategory.bind(this);
    this.DeleteServiceCategory = this.DeleteServiceCategory.bind(this);
    this.getService_categories = this.getService_categories.bind(this);
    this.checkDataAssociated = this.checkDataAssociated.bind(this);
  }

  // New Mthods
  saveServiceCategory() {
    let service_category = {
      name: this.state.category_name,
      description: this.state.category_description,
      images: this.state.images,
    };

    ServiceCategoriesService.SaveServiceCategory(service_category)
      .then((res) => {
        Swal.fire({
          type: 'success',
          title: `Categoría ${res.data.name} agregada`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getService_categories();
      })
      .catch((err) => {
        Swal.fire({
          type: 'error',
          title: `Complete los campos requeridos (Nombre)`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }

  selectToEdit(service_category) {
    this.state.inputRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    this.setState({
      category_name: service_category.name,
      category_description: service_category.description,
      images: service_category.images,
      idToUpdate: service_category._id,
      toUpdate: true,
    });
  }

  async UpdateServiceCategory(service_category_id) {
    let service_category_to_update = {
      name: this.state.category_name,
      description: this.state.category_description,
      images: this.state.images,
    };

    try {
      let service_category_updated = await ServiceCategoriesService.UpdateServiceCategory(service_category_to_update, service_category_id);
      if (service_category_updated) {
        this.setState({ toUpdate: false, category_name: '', category_description: '', images: [''] });
      }
      Swal.fire({
        type: 'success',
        title: `Categoría actualizada`,
        showConfirmButton: false,
        timer: 1500,
      });
      this.getService_categories();
    } catch (err) {
      console.log(err);
    }
  }

  async DeleteServiceCategory(service_category_id) {
    let ableToDelte = await this.checkDataAssociated(service_category_id);
    try {
      if (ableToDelte === true) {
        let service_category = await ServiceCategoriesService.DeleteServiceCategory(service_category_id);
        if (service_category) {
          Swal.fire({
            type: 'success',
            title: 'Categoría eliminada',
            showConfirmButton: false,
            timer: 1500,
          });
          this.getService_categories();
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'Esta categoría tiene servicios asociados',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getService_categories() {
    try {
      let service_categories = await ServiceCategoriesService.GetService_categories();
      this.props.setServiceCategories(service_categories.data);
    } catch (err) {
      console.log(err);
    }
  }

  async checkDataAssociated(service_category_id) {
    try {
      let services = await ServiceService.GetServices();

      //check if service_category_id is in services
      let service_category_in_services = services.data.filter((service) => service.category._id === service_category_id);

      if (service_category_in_services.length > 0) {
        return false;
      } else {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
  }

  // EndNewMethods

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      indexLast: pageNumber * this.state.perpage - 1,
      indexInitial: (pageNumber - 1) * this.state.perpage,
    });
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  }

  changeNameFilter(e) {
    this.setState({ namefilter: e.target.value, activePage: 1 });
  }
  render() {
    return (
      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          <button className="btn btn-primary mb-3" onClick={() => this.setState({ show: !this.state.show })}>
            Añadir categoría
          </button>
        </div>
        {this.state.show ? (
          <>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5>Categoría de servicio</h5>
                </div>
                <div className="card-body" ref={this.state.inputRef}>
                  <div className="form-group">
                    <label>Nombre de la categoría</label>
                    <input
                      type="text"
                      className="form-control mb-1"
                      name="category_name"
                      placeholder="Indica el nombre de la categoría"
                      onChange={this.handleInputChange}
                      value={this.state.category_name}
                    />
                    <label>Descripción de la categoría</label>
                    <input
                      type="text"
                      className="form-control"
                      name="category_description"
                      placeholder="Indica la descripción de la categoría"
                      onChange={this.handleInputChange}
                      value={this.state.category_description}
                    />
                    {/*Input to add image, the database and state is ready to acept it  
                <label>Imágenes para la categoría</label>
                <input
                  type="file"
                  name="images"
                  className="form-control"
                /> */}
                  </div>
                  {this.state.toUpdate ? (
                    <button onClick={() => this.UpdateServiceCategory(this.state.idToUpdate)} className="btn btn-primary">
                      Editar Categoría
                    </button>
                  ) : (
                    <button onClick={this.saveServiceCategory} className="btn btn-primary">
                      Guardar categoría
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div className="row d-flex flex-row">
                <h5 className="col-9">Listado de categorías</h5>
                <div className="filterforms col-3 justify-content-end">
                  <div className="form-group">
                    <input value={this.state.namefilter} onChange={this.changeNameFilter} className="form-control" placeholder="Buscar"></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-block table-border-style">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: '100px' }}>#</th>
                      <th>Nombre de la categoría</th>
                      <th>Descripción de la categoría</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.service_categories
                      .filter(
                        (a) =>
                          this.state.namefilter === '' ||
                          a.name
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/\p{Diacritic}/gu, '')
                            .includes(
                              this.state.namefilter
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/\p{Diacritic}/gu, '')
                            )
                      )
                      .map((data, index) =>
                        this.state.indexInitial <= index && index <= this.state.indexLast ? (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{data.name}</td>
                            <td>{data.description}</td>
                            <td style={{ width: '60px', cursor: 'pointer' }}>
                              <i
                                onClick={async () => {
                                  await this.setState({ show: true });
                                  this.selectToEdit(data);
                                }}
                                className="feather icon-edit-2"
                              ></i>
                            </td>
                            <td style={{ width: '60px', cursor: 'pointer' }}>
                              <Popconfirm
                                placement="top"
                                title={'¿Realmente desea eliminar esta especialidad?'}
                                onConfirm={() => this.DeleteServiceCategory(data._id)}
                                okText="Si claro"
                                cancelText="No"
                              >
                                <i className="feather icon-trash-2"></i>
                              </Popconfirm>
                            </td>
                          </tr>
                        ) : null
                      )}
                  </tbody>
                </table>
                <Pagination
                  hideFirstLastPages
                  pageRangeDisplayed={6}
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.perpage}
                  totalItemsCount={
                    this.props.service_categories.filter(
                      (a) => this.state.namefilter === '' || a.name.toLowerCase().includes(this.state.namefilter.toLowerCase())
                    ).length
                  }
                  onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  toastr: state.toastr,
  service_categories: state.service_categories,
});
const mapDispatchToProps = (dispatch) => ({
  addSpecalityStore(data) {
    dispatch({
      type: 'ADD_TO_LIST',
      data: data,
    });
  },
  deleteSpecialityStore(index) {
    dispatch({
      type: 'DELETE_TO_LIST',
      index: index,
    });
  },
  //new
  setServiceCategories(value) {
    dispatch({
      type: 'FETCH_SERVICE_CATEGORIES',
      value: value,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCategories);
