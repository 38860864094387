import global from "./global";
import * as axios from "axios";

const URL = global.URL;
const headers = {
  Authorization: localStorage.getItem("token"),
};

// New Methods

const GetServices = async () => {
  try {
    console.log(URL);
    return await axios.get(URL + "/api/service");
  } catch (error) {
    console.log(error);
  }
};

const SaveService = async (serviceObj) => {
  try {
    return await axios.post(URL + "/api/service", serviceObj);
  } catch (error) {
    console.log(error);
  }
};

const UpdateService = async (service, id) => {
  try {
    return await axios.put(URL + "/api/service/" + id, service, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

const DeleteService = async (id) => {
  try {
    return await axios.delete(URL + "/api/service/" + id, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

// End New Methods

export default {
  GetServices,
  SaveService,
  UpdateService,
  DeleteService,
};
