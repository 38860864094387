import React, { Component } from 'react';
import DoctorService from '../../../services/doctor.service';

import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';

import Swal from 'sweetalert2';
import businessService from '../../../services/business.service';
import { Popconfirm } from 'antd';
class BusinessTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business: [],
      activePage: 1,
      perpage: 12,
      indexLast: 11,
      indexInitial: 0,
      namefilter: '',
    };
  }
  componentDidMount() {
    this.getBusiness(this.props.city, this.props.type);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.city !== this.props.city ||
      prevProps.type !== this.props.type ||
      prevProps.show !== this.props.show
    ) {
      this.getBusiness(this.props.city, this.props.type);
    }
  }
  handlePageChange = (pageNumber) => {
    this.setState({
      activePage: pageNumber,
      indexLast: pageNumber * this.state.perpage - 1,
      indexInitial: (pageNumber - 1) * this.state.perpage,
    });
  };
  changeNameFilter = (e) => {
    this.setState({ namefilter: e.target.value, activePage: 1, indexLast: this.state.perpage - 1,
      indexInitial: 0 * this.state.perpage });
  };
  getBusiness = (city, type) => {
    businessService.GetAllByType(city, type).then((res) => {
      this.setState({ business: res.data.business, activePage: 1 });
    });
  };
  Delete = async (id) => {
    var doctor = await businessService.Delete(id);
    if (doctor) {
      this.getBusiness(this.props.city, this.props.type);
      Swal.fire({
        type: 'success',
        title: 'Doctor eliminado exitosamente',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  render() {
    return (
      <div className='card Recent-Users'>
        <div className='card-block px-0 py-3'>
          <div className='filterforms'>
            <div className='form-group'>
              <input
                value={this.state.namefilter}
                onChange={this.changeNameFilter}
                className='form-control'
                placeholder='Buscar'
              ></input>
            </div>
          </div>
          <div className='table-responsive'>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th></th>
                  <th>Nombre y especialidades</th>
                  <th>Dirección</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {this.state.business
                  .filter(
                    (a) =>
                      this.state.namefilter === '' ||
                      a.name
                        .toLowerCase()
                        .includes(this.state.namefilter.toLowerCase())
                  )
                  .map((data, index) =>
                    this.state.indexInitial <= index &&
                    index <= this.state.indexLast ? (
                      <tr key={data._id} className='unread'>
                        <td style={{ width: '50px' }}>
                          <img
                            className='rounded-circle'
                            style={{ width: '50px', height: '50px' }}
                            src={data.profilepic}
                            alt='activity-user'
                          />
                        </td>
                        <td>
                          <h6 className='mb-1'>{data.name}</h6>
                        </td>
                        <td>
                          <h6 className='mb-1'>
                            {data.city}, {data.state}
                          </h6>
                          <p className='m-0'>{data.direction}</p>
                        </td>
                        <td style={{ width: '60px', cursor: 'pointer' }}>
                          <i
                            onClick={() => this.props.onUpdate(data)}
                            className='feather icon-edit-2'
                          ></i>
                        </td>
                        <td style={{ width: '60px', cursor: 'pointer' }}>
                        <Popconfirm
                    placement='top'
                    title={'¿Realmente desea eliminar este centro?'}
                    onConfirm={() => this.Delete(data._id)}
                    okText='Si claro'
                    cancelText='No'
                  >
                      <i
                            className='feather icon-trash-2'
                          ></i>
                  </Popconfirm>
                          
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            </table>
            <Pagination
              hideFirstLastPages
              pageRangeDisplayed={this.state.perpage}
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.perpage}
              totalItemsCount={
                this.state.business.filter(
                  (a) =>
                    this.state.namefilter === '' ||
                    a.name
                      .toLowerCase()
                      .includes(this.state.namefilter.toLowerCase())
                ).length
              }
              onChange={(pageNumber) => this.handlePageChange(pageNumber)}
            />
            {this.state.business.filter(
              (a) =>
                this.state.namefilter === '' ||
                a.name
                  .toLowerCase()
                  .includes(this.state.namefilter.toLowerCase())
            ).length <= 0 ? (
              <p style={{ textAlign: 'center' }}>
                No hay consultorios registrados
              </p>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTable);
