import React, { Component } from 'react';
import Country from '../../../services/country';
import HospitalService from '../../../services/hospital.service';
import { connect } from 'react-redux';
import './doctors.css';
import { Tabs } from 'antd';
import PremiumDoctor from './premiumdoctor';
import NormalDoctor from './normaldoctors';
import EmergencyDoctors from './emergencydoctors';
import DoctorForm from './doctorform';

import DisableDoctor from './disabledoctor';
const { TabPane } = Tabs;

class Doctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: [],
      hospitals: [],
      hospital: 'a',
      city: 'Puerto Ordaz',
      activeState: 'Bolívar',
      index: 0,
      show: false,
      /*  */

      /*  */
      speciality: [],
      services: '',
      status: 0,
      specialityFilter: '',
      namefilter: '',
      event: 0,
      updateUser: null,
    };
    this.changeState = this.changeState.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.getHospitals = this.getHospitals.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
    this.handleInputHospitalChange = this.handleInputHospitalChange.bind(this);
  }
  async getHospitals(city) {
    var hospitals = await HospitalService.ListHospitals(city);
    console.log(hospitals.hospital.length > 0);
    if (hospitals.hospital.length > 0) {
      this.setState({ hospital: '' });
      this.setState({ hospitals: hospitals.hospital });
    } else {
      this.setState({ hospital: '' });
      this.setState({ hospitals: [] });
    }
  }
  handleInputHospitalChange(e) {
    var value = e.target.value;
    this.setState({ hospital: value });
  }
  changeState(event) {
    this.setState({
      activeState: event.target.value,
      index: event.target.value,
      city: this.state.country.filter((state) => state.state === event.target.value)[0].city[0].city,
      indexLast: 5,
      indexInitial: 0,
    });
    this.getHospitals(this.state.country.filter((state) => state.state === event.target.value)[0].city[0].city);
  }
  changeCity(event) {
    this.setState({ city: event.target.value, indexLast: 5, indexInitial: 0 });
    this.getHospitals(event.target.value);
    console.log(this.state.city);
  }
  componentDidMount() {
    Country.GetCountry().then((response) => {
      this.setState({ country: response.data });
      this.getHospitals(this.state.city);
      this.setState({ event: Math.round() * 100 });
    });
  }
  /* async deleteDoctor(userId, doctorId) {
    console.log(userId, doctorId);
    var user = await UserService.DeleteUser(userId);
    if (user.data) {
      var doctor = await DoctorService.DeleteDoctor(doctorId);
      if (doctor.data) {
        var ids = this.state.premiumId;
        if (ids.indexOf(doctorId) >= 0) {
          ids.splice(ids.indexOf(doctorId), 1);
          this.setState({ premiumId: ids });
          this.updatePremiumDoctor();
        }
        this.getDoctors(this.state.city);
        Swal.fire({
          type: 'success',
          title: 'Doctor eliminado',
          showConfirmButton: false,
          timer: 1500
        });
      }
    }
  } */

  settoUpdate(doctor) {
    this.getHospitals(this.state.city);
    this.setState({
      show: true,
      updateUser: doctor.user._id,
      firstname: doctor.user.firstname,
      lastname: doctor.user.lastname,
      phone: doctor.user.phone,
      sex: doctor.user.sex,
      profilepic: doctor.user.profilepic,
      role: 'doctor',
      password: 'doctordefaultpass2019',
      whatsapp: doctor.user.whatsapp,
      email: doctor.user.email,

      web: doctor.user.web,
      facebook: doctor.user.facebook,
      instagram: doctor.user.instagram,
      twitter: doctor.user.twitter,
      youtube: doctor.user.youtube,
      linkedin: doctor.user.linkedin,
      tiktok: doctor.user.tiktok,
      /*  */
      speciality: doctor.speciality,
      services: doctor.services,
      status: 0,
      consulting_room: [
        {
          direction: doctor.consulting_room[0].direction,
          time_start: doctor.consulting_room[0].time_start,
          time_end: doctor.consulting_room[0].time_end,
          days: doctor.consulting_room[0].days,
          hospital: doctor.consulting_room[0].hospital._id,
        },
      ],
    });
  }

  closeWindow(e) {
    console.log('close ', e.update);
    if (e.update) {
      this.changeEvent({ action: Math.random() * 100 });
    }

    this.setState({ show: false, updateUser: null });
  }
  changeEvent(e) {
    console.log('event', e);
    if (e.action) {
      this.setState({ event: e.action });
    }
    console.log(e.update);
    if (e.update) {
      this.setState({ updateUser: e.update, show: true });
    }
  }
  render() {
    return (
      <>
        {this.state.show ? <DoctorForm country={this.state.country} onClose={(e) => this.closeWindow(e)} updateUser={this.state.updateUser} /> : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'baseline',
          }}
        >
          <button className="btn btn-primary" onClick={() => this.setState({ show: true })}>
            Añadir doctor
          </button>

          <div className="form-group">
            <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeState} value={this.state.activeState}>
              {this.state.country
                .filter((state) => state.active === true)
                .map((state, index) => (
                  <option value={state.state} key={state._id}>
                    {state.state}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group" style={{ marginLeft: '20px' }}>
            <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeCity} value={this.state.city}>
              <option value="all">Todas las ciudades</option>
              {this.state.country.length > 0
                ? this.state.country
                    .filter((state) => state.state === this.state.activeState)[0]
                    .city.filter((city) => city.active === true)
                    .map((city, index) => (
                      <option value={city.city} key={city.city._id}>
                        {city.city}
                      </option>
                    ))
                : null}
            </select>
          </div>
          <div className="form-group" style={{ marginLeft: '20px' }}>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={this.handleInputHospitalChange}
              name="hospital"
              value={this.state.hospital}
            >
              <option disabled>Selecciona un centro médico</option>
              <option value="">Todos los hospitales</option>
              {this.state.hospitals.length <= 0 ? <option disabled>No hay hospitales en {this.state.city}</option> : null}
              {this.state.hospitals.map((hospital, index) => (
                <option value={hospital._id} key={hospital._id}>
                  {hospital.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <Tabs defaultActiveKey="1">
          <TabPane tab={'Listado de Doctores'} key="1">
            <NormalDoctor
              updateUser={false}
              hospital={this.state.hospital}
              city={this.state.city}
              action={this.state.event}
              changeEvent={this.changeEvent.bind(this)}
            />
          </TabPane>
          <TabPane tab="Doctores premium" key="2">
            <PremiumDoctor action={this.state.event} countrystate={this.state.country} />
          </TabPane>
          <TabPane tab="Doctores deshabilitados" key="3">
            <DisableDoctor
              action={this.state.event}
              changeEvent={this.changeEvent.bind(this)}
              hospital={this.state.hospital}
              city={this.state.city}
            />
          </TabPane>
          <TabPane tab={'Atención a emergencias'} key="4">
            <EmergencyDoctors
              updateUser={false}
              hospital={this.state.hospital}
              city={this.state.city}
              action={this.state.event}
              changeEvent={this.changeEvent.bind(this)}
            />
          </TabPane>
        </Tabs>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  doctors: state.doctors,
  specialities: state.specialities,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Doctor);
