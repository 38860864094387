import React, { Component, createRef } from "react";
import SpecialityService from "../../../services/speciality.service";
import DoctorService from "../../../services/doctor.service";
import Swal from "sweetalert2";
import { connect } from "react-redux";

import Pagination from "react-js-pagination";
import { Popconfirm } from "antd";
class Specialities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speciality: "",
      update: "",
      index: "",
      inputRef: createRef(),
      activePage: 1,
      perpage: 12,
      indexLast: 11,
      indexInitial: 0,
      namefilter: "",
      inSave: false,
      inDelete: false,
      onEdit: false,
    };
    this.addSpecality = this.addSpecality.bind(this);
    this.deleteSpeciality = this.deleteSpeciality.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectToEdit = this.selectToEdit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.changeNameFilter = this.changeNameFilter.bind(this);
  }
  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      indexLast: pageNumber * this.state.perpage - 1,
      indexInitial: (pageNumber - 1) * this.state.perpage,
    });
  }
  diferentSpeciality() {
    for (let i = 0; i < this.props.specialities.length; i++) {
      let element = this.props.specialities[i];
      if (
        element.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "") ===
        this.state.speciality
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      ) {
        if (this.state.update === element._id) return false;
        return true;
      }
    }
    return false;
  }
  async addSpecality() {
    this.setState({ inSave:true })
    if (this.diferentSpeciality()) {
      this.setState({ inSave:false })
      Swal.fire({
        type: "warning",
        title: `La especialidad ${this.state.speciality} ya se encuentra en la lista`,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      if (this.state.update === "") {
        if (this.state.speciality !== "") {
          let speciality = await SpecialityService.SaveSpeciality({
            name: this.state.speciality,
          });
          if (speciality.data.speciality) {
            this.setState({ speciality: "" });
            this.props.addSpecalityStore(speciality.data.speciality);
            this.setState({ inSave:false })
            Swal.fire({
              type: "success",
              title: "Especialidad agregada",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } else {
        if (this.state.speciality !== "") {
          try {
            let speciality = await SpecialityService.UpdateSpeciality(
              { name: this.state.speciality },
              this.state.update
            );
  
            if (speciality.data) {
              this.props.getSpecialities();
              this.setState({ speciality: "", update: "", index: "" });
              this.setState({ inSave:false })
              this.setState({ onEdit: false })
              Swal.fire({
                type: "success",
                title: "Especialidad actualizada",
                showConfirmButton: false,
                timer: 1500
              });
            }
          } catch (error) {
            this.setState({ onEdit: false })
            this.setState({ inSave: false })
              Swal.fire({
                type: "error",
                title: "Intente más tarde",
                showConfirmButton: false,
                timer: 1500
              });
            }
        }
      }
    }
  }

  async CheckSpeciality(id) {
    let doctors = await DoctorService.ListAllDoctors(-1, "all");
    let specialityInUse = doctors.doctors.map((doctor) => doctor.speciality);

    let canDelete = true;
    specialityInUse.map((specialityArray) => {
      specialityArray.map((speciality) => {
        if (speciality._id === id) {
          canDelete = false;
          Swal.fire({
            type: "error",
            title: "Esta especialidad pertenece a un médico",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    });

    return canDelete;
  }

  async deleteSpeciality(id, index) {
    this.setState({ inDelete: true })
    let canDelete = await this.CheckSpeciality(id);

    if (canDelete) {
      var speciality = await SpecialityService.DeleteSpeciality(id);
      if (speciality) {
        var spec = [];
        spec = await SpecialityService.GetSpecialityes("-1");
        this.props.getSpecialities();
        this.setState({ inDelete: false })
        Swal.fire({
          type: "success",
          title: "Especialidad eliminada",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }
  handleInputChange(e) {
    this.setState({ speciality: e.target.value });
  }
  selectToEdit(id, speciality, index) {
    this.setState({ onEdit: true })
    this.state.inputRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    this.setState({ speciality: speciality, update: id, index: index });
  }
  changeNameFilter(e) {
    this.setState({ namefilter: e.target.value, activePage: 1, indexLast: this.state.perpage - 1, indexInitial: 0 * this.state.perpage });
  }
  render() {
    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <h5>Datos de la especialidad</h5>
            </div>
            <div className="card-body" ref={this.state.inputRef}>
              <div className="form-group">
                <label>Nombre de la especialidad</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Indica el nombre de la especialidad"
                  onChange={this.handleInputChange}
                  value={this.state.speciality}
                />
              </div>
              <div className="d-flex align-items-center">
                <button onClick={this.addSpecality} className={ this.state.onEdit ? "btn btn-danger mb-0" : "btn btn-primary mb-0" } >
                  { this.state.onEdit ? "Editar Especialidad" : "Guardar especialidad" }
                </button>
                { this.state.inSave ? (
                    <div class="spinner-grow text-success" role="status">
                      <span class="my-auto sr-only">Loading...</span>
                    </div>
                  ) : <></>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <h5>Listado de especialidades</h5>
            </div>
            <div className="card-block table-border-style">
              <div className="filterforms" style={{ justifyContent: "flex-end" }}>
                <div className="form-group">
                  <input value={this.state.namefilter} onChange={this.changeNameFilter} className="form-control" placeholder="Buscar"></input>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "100px" }}>#</th>
                      <th>{"Nombre de la especialidad"}</th>
                      <th></th>
                      <th>
                      { this.state.inDelete ? (
                          <div class="spinner-grow text-success" role="status">
                            <span class="my-auto sr-only">Loading...</span>
                          </div>
                          ) : <></>
                        }
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.specialities
                      .filter(
                        (a) =>
                          this.state.namefilter === "" ||
                          a.name
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/\p{Diacritic}/gu, "")
                            .includes(
                              this.state.namefilter
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/\p{Diacritic}/gu, "")
                            )
                      )
                      .map((data, index) =>
                        this.state.indexInitial <= index &&
                        index <= this.state.indexLast ? (
                          <tr key={`speciality${index}`}>
                            <th scope="row">{index + 1}</th>
                            <td>{data.name}</td>
                            <td style={{ width: "60px", cursor: "pointer" }}>
                              <i
                                onClick={() =>
                                  this.selectToEdit(data._id, data.name, index + 1)
                                }
                                className="feather icon-edit-2"
                              ></i>
                            </td>
                            <td style={{ width: "60px", cursor: "pointer" }}>
                              <Popconfirm
                                placement="top"
                                title={"¿Realmente desea eliminar esta especialidad?"}
                                onConfirm={() => this.deleteSpeciality(data._id, index)}
                                okText="Si claro"
                                cancelText="No"
                              >
                                <i className="feather icon-trash-2"></i>
                              </Popconfirm>
                            </td>
                          </tr>
                        ) : null
                      )}
                  </tbody>
                </table>
                <Pagination
                  hideFirstLastPages
                  pageRangeDisplayed={6}
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.perpage}
                  totalItemsCount={
                    this.props.specialities.filter(
                      (a) =>
                        this.state.namefilter === "" ||
                        a.name
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/\p{Diacritic}/gu, "")
                          .includes(
                            this.state.namefilter
                              .toLowerCase()
                              .normalize("NFD")
                              .replace(/\p{Diacritic}/gu, "")
                          )
                    ).length
                  }
                  onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialities: state.specialities,
  toastr: state.toastr,
});
const mapDispatchToProps = (dispatch) => ({
  addSpecalityStore(data) {
    dispatch({
      type: "ADD_TO_LIST",
      data: data,
    });
  },
  deleteSpecialityStore(index) {
    dispatch({
      type: "DELETE_TO_LIST",
      index: index,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Specialities);
