import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import HospitalService from '../../../services/hospital.service';
import Country from '../../../services/country';
import Swal from 'sweetalert2';
import Pagination from 'react-js-pagination';
import { Popconfirm } from 'antd';
import DoctorService from '../../../services/doctor.service';
class Hospitals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitals: [],
      country: [],
      city: 'Puerto Ordaz',
      activeState: 'Bolívar',
      index: 0,
      name: '',
      direction: '',
      cityh: '',
      lat: 0,
      lng: 0,
      stateh: '',
      phone: '',
      update: '',
      inputRef: createRef(),
      activePage: 1,
      perpage: 12,
      indexLast: 11,
      indexInitial: 0,
      namefilter: '',
      inSave: false,
    };
    this.getDoctors = this.getHospitals.bind(this);
    this.changeState = this.changeState.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.saveHospital = this.saveHospital.bind(this);
    this.DeleteHospital = this.DeleteHospital.bind(this);
    this.setHospitalUpdate = this.setHospitalUpdate.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.changeNameFilter = this.changeNameFilter.bind(this);
  }
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, indexLast: pageNumber * this.state.perpage - 1, indexInitial: (pageNumber - 1) * this.state.perpage });
    console.log('pag');
  }
  async getHospitals(city) {
    var hospitals = await HospitalService.ListHospitals(city);
    this.setState({ hospitals: hospitals.hospital });
  }
  changeState(event) {
    this.setState({
      activeState: event.target.value,
      index: event.target.value,
      stateh: event.target.value,
      cityh: this.state.country.filter((state) => state.state === event.target.value)[0].city[0].city,
      city: this.state.country.filter((state) => state.state === event.target.value)[0].city[0].city,
      indexLast: 5,
      indexInitial: 0,
    });
    this.getHospitals(this.state.country.filter((state) => state.state === event.target.value)[0].city[0].city);
  }
  changeCity(event) {
    this.setState({
      city: event.target.value,
      cityh: event.target.value,
      indexLast: 5,
      indexInitial: 0,
    });
    this.getHospitals(event.target.value);
  }
  onInputChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    console.log(name, value);
    this.setState({ [name]: value });
  }
  componentDidMount() {
    Country.GetCountry().then((response) => {
      this.setState({
        country: response.data,
        stateh: 'Bolívar',
        cityh: 'Puerto Ordaz',
      });
    });
    this.getHospitals(this.state.city);
  }

  async CheckHospital(id) {
    let doctors = await DoctorService.ListAllDoctors(-1, 'all');
    let hospitalInUse = doctors.doctors.map((doctor) => doctor.consulting_room);

    let canDelete = true;
    hospitalInUse.map((consultingArray) => {
      consultingArray.map((hospitalArray) => {
        if (hospitalArray.hospital !== null) {
          if (hospitalArray.hospital._id === id) {
            Swal.fire({
              type: 'error',
              title: 'El centro médico está asociado a un doctor',
              showConfirmButton: true,
              timer: 1500,
            });
            canDelete = false;
            return false;
          }
        }
      });
    });

    return canDelete;
  }
  async DeleteHospital(index, id) {
    let canDelete = await this.CheckHospital(id);
    if (canDelete) {
      var hospital = await HospitalService.DeleteHospital(id);
      if (hospital.data) {
        this.state.hospitals.splice(index, 1);
        this.setState({ hospitals: this.state.hospitals });
        Swal.fire({
          type: 'success',
          title: 'Centro médico eliminado',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  async saveHospital() {
    this.setState({ inSave: true });
    if (this.state.update === '') {
      if (this.state.name !== '' && this.state.direction !== '' && this.state.stateh !== '') {
        let hospital = await HospitalService.SaveHospital({
          name: this.state.name,
          direction: this.state.direction,
          lat: this.state.lat,
          lng: this.state.lng,
          state: this.state.stateh,
          city: this.state.cityh,
          phone: this.state.phone,
        });
        if (hospital.data.hospital) {
          this.setState({
            name: '',
            direction: '',
            lat: 0,
            lng: 0,
            phone: '',
          });
          this.getHospitals(this.state.city);
          this.setState({ inSave: false });
          Swal.fire({
            type: 'success',
            title: 'Centro médico agregado',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.setState({ inSave: false });
        Swal.fire({
          type: 'warning',
          title: 'Debe rellenar todos los campos',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      if (this.state.name !== '' && this.state.direction !== '' && this.state.stateh !== '') {
        let hospital = await HospitalService.UpdateHospital(
          {
            name: this.state.name,
            direction: this.state.direction,
            lat: this.state.lat,
            lng: this.state.lng,
            state: this.state.stateh,
            city: this.state.cityh,
            phone: this.state.phone,
          },
          this.state.update
        );
        if (hospital.data.hospital) {
          this.setState({
            name: '',
            direction: '',
            lat: 0,
            lng: 0,
            stateh: this.state.state,
            cityh: this.state.city,
            phone: '',
            update: '',
          });
          this.getHospitals(this.state.city);
          this.setState({ inSave: false });
          Swal.fire({
            type: 'success',
            title: 'Centro médico actualizado',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.setState({ inSave: false });
        Swal.fire({
          type: 'warning',
          title: 'Debe rellenar todos los campos',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }
  setHospitalUpdate(hospital) {
    this.state.inputRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    this.setState({
      name: hospital.name,
      direction: hospital.direction,
      cityh: hospital.city,
      lat: hospital.lat,
      lng: hospital.lng,
      stateh: hospital.state,
      phone: hospital.phone,
      update: hospital._id,
    });
  }
  changeNameFilter(e) {
    this.setState({ namefilter: e.target.value, activePage: 1 });
  }
  render() {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="form-group">
            {/*    Eliminar depués de Agosto 2021         <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeState} value={this.state.index}>
              {this.state.country.map((state, index) => (
                <option value={index} key={state._id}>
                  {state.state}
                </option>
              ))}
            </select> */}
            <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeState} value={this.state.activeState}>
              {this.state.country
                .filter((state) => state.active === true)
                .map((state, index) => (
                  <option value={state.state} key={state._id}>
                    {state.state}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group" style={{ marginLeft: '20px' }}>
            {/*   Eliminar depués de Agosto 2021          <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeCity} value={this.state.city}>
              {this.state.country.length > 0
                ? this.state.country[this.state.index].city.map((city, index) => (
                    <option value={city.city} key={index}>
                      {city.city}
                    </option>
                  ))
                : null}
            </select> */}
            <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeCity} value={this.state.city}>
              <option value="all">Todas las ciudades</option>
              {this.state.country.length > 0
                ? this.state.country
                    .filter((state) => state.state === this.state.activeState)[0]
                    .city.filter((city) => city.active === true)
                    .map((city, index) => (
                      <option value={city.city} key={city.city._id}>
                        {city.city}
                      </option>
                    ))
                : null}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="card" ref={this.state.inputRef}>
              <div className="card-header">
                <h5>Nueva dirección</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <input
                    onChange={this.onInputChange}
                    name="name"
                    value={this.state.name}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Nombre del centro médico"
                  />
                </div>
                <div className="form-group">
                  <input
                    value={this.state.direction}
                    onChange={this.onInputChange}
                    name="direction"
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Dirección"
                  />
                  <span>
                    {this.state.stateh} / {this.state.cityh}
                  </span>
                </div>
                {/*  <div className="form-group">
                                        <input value={this.state.data.state}
                                            onChange={this.onInputChange}
                                            name="state"
                                            type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Estado"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input value={this.state.data.city}
                                            onChange={this.onInputChange}
                                            name="city"
                                            type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Ciudad"
                                        />
                                    </div> */}
                {/*  <div className="form-group">
                                        <input value={this.state.lat}
                                            onChange={this.onInputChange}
                                            name="lat"
                                            type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Latitud"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input value={this.state.lng}
                                            onChange={this.onInputChange}
                                            name="lng"
                                            type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Longitud"
                                        />
                                    </div> */}
                <div className="form-group">
                  <input
                    value={this.state.phone}
                    onChange={this.onInputChange}
                    name="phone"
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Telefono"
                  />
                </div>
                <button className="btn btn-primary" onClick={this.saveHospital}>
                  Guardar dirección
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="card">
              <div className="card-header">
                <h5>Listado de direcciones</h5>
              </div>
              <div className="card-block table-border-style">
                <div className="filterforms" style={{ justifyContent: 'flex-end' }}>
                  <div className="form-group">
                    <input value={this.state.namefilter} onChange={this.changeNameFilter} className="form-control" placeholder="Buscar"></input>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Nombre del centro médico</th>
                        <th>Dirección</th>
                        <th>Ciudad</th>
                        <th>Estado</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.hospitals
                        .filter(
                          (a) =>
                            this.state.namefilter === '' ||
                            a.name
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/\p{Diacritic}/gu, '')
                              .includes(
                                this.state.namefilter
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/\p{Diacritic}/gu, '')
                              )
                        )
                        .map((data, index) =>
                          this.state.indexInitial <= index && index <= this.state.indexLast ? (
                            <tr key={index}>
                              <td>{data.name}</td>
                              <td>{data.direction}</td>
                              <td>{data.city}</td>
                              <td>{data.state}</td>
                              <td style={{ width: '60px' }}>
                                <i style={{ cursor: 'pointer' }} className="feather icon-edit-2" onClick={() => this.setHospitalUpdate(data)}></i>
                              </td>
                              <td style={{ width: '60px' }}>
                                <Popconfirm
                                  placement="top"
                                  title={'¿Realmente desea eliminar este centro médico?'}
                                  onConfirm={() => this.DeleteHospital(index, data._id)}
                                  okText="Si claro"
                                  cancelText="No"
                                >
                                  <i style={{ cursor: 'pointer' }} className="feather icon-trash-2"></i>
                                </Popconfirm>
                              </td>
                            </tr>
                          ) : null
                        )}
                    </tbody>
                  </table>
                  <Pagination
                    hideFirstLastPages
                    pageRangeDisplayed={this.state.perpage}
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.perpage}
                    totalItemsCount={
                      this.state.hospitals.filter(
                        (a) => this.state.namefilter === '' || a.name.toLowerCase().includes(this.state.namefilter.toLowerCase())
                      ).length
                    }
                    onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                  />
                  {this.state.hospitals.length <= 0 ? <p style={{ textAlign: 'center' }}>No hay hospitales en esta localidad</p> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Hospitals);
