import React, { Component } from "react";
import UserService from "../../../services/user.service";
import { connect } from "react-redux";
import logo from "../../../assets/img/logo.png";
import "./menu.css";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.Logout = this.Logout.bind(this);
  }

  Logout() {
    UserService.Logout();
  }
  render() {
    return (
      <nav className={"pcoded-navbar " + (this.props.showMenu ? "mob-open" : "")}>
        <div className="navbar-wrapper">
          <div className="navbar-brand header-logo">
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
              onClick={() => this.props.changePage("doctors")}
              className="b-brand"
            >
              <img src={logo} alt="logo" style={{ width: "40%" }} />
            </div>
            {/* <a className="mobile-menu" id="mobile-collapse" onClick="javascript"><span></span></a> */}
          </div>
          <div className="navbar-content scroll-div">
            <ul className="nav pcoded-inner-navbar">
              <li className="nav-item pcoded-menu-caption">
                <label>Menú</label>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => this.props.changePage("doctors")}
                className={"nav-item " + (this.props.page === "doctors" ? "active" : null)}
              >
                <div className="nav-link">
                  <span className="pcoded-micon">
                    <i className="fas fa-user-md"></i>
                  </span>
                  <span className="pcoded-mtext">Doctores</span>
                </div>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => this.props.changePage("hospitals")}
                className={"nav-item " + (this.props.page === "hospitals" ? "active" : null)}
              >
                <div className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="fas fa-hospital"></i>
                  </span>
                  <span className="pcoded-mtext">Direcciones</span>
                </div>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => this.props.changePage("speciality")}
                className={"nav-item " + (this.props.page === "speciality" ? "active" : null)}
              >
                <div className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="fas fa-stethoscope"></i>
                  </span>
                  <span className="pcoded-mtext">Especialidades</span>
                </div>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => this.props.changePage("business")}
                className={"nav-item " + (this.props.page === "business" ? "active" : null)}
              >
                <div className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="fas fa-store-alt"></i>
                  </span>
                  <span className="pcoded-mtext">Centros de especialidades</span>
                </div>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => this.props.changePage("countries")}
                className={"nav-item " + (this.props.page === "countries" ? "active" : null)}
              >
                <div className="nav-link ">
                  <span className="pcoded-micon">
                    <i class="fas fa-map-marked-alt"></i>
                  </span>
                  <span className="pcoded-mtext">Estados y ciudades</span>
                </div>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => this.props.changePage("service_categories")}
                className={"nav-item " + (this.props.page === "service_categories" ? "active" : null)}
              >
                <div className="nav-link ">
                  <span className="pcoded-micon">
                    <i class="fa fa-notes-medical"></i>
                  </span>
                  <span className="pcoded-mtext">Categorías de servicios</span>
                </div>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => this.props.changePage("banners")}
                className={"nav-item " + (this.props.page === "banners" ? "active" : null)}
              >
                <div className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="fas fa-images"></i>
                  </span>
                  <span className="pcoded-mtext">Banners</span>
                </div>
              </li>
            </ul>
            <ul className="nav pcoded-inner-navbar">
              <li className="nav-item pcoded-menu-caption">
                <label>Opciones</label>
              </li>

              {/* <li
								style={{ cursor: 'pointer' }}
								onClick={() => this.props.changePage('profile')}
								className={'nav-item ' + (this.props.page === 'profile' ? 'active' : null)}>
								<div className="nav-link ">
									<span className="pcoded-micon">
										<i className="feather icon-user"></i>
									</span>
									<span className="pcoded-mtext">Perfil</span>
								</div>
							</li> */}
              <li style={{ cursor: "pointer" }} onClick={this.Logout} className="nav-item">
                <div className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-log-out"></i>
                  </span>
                  <span className="pcoded-mtext">Salir</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.page,
  showMenu: state.showMenu,
});
const mapDispatchToProps = (dispatch) => ({
  changePage(page) {
    dispatch({
      type: "CHANGE_PAGE",
      page: page,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
