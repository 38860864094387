import React, { Component } from 'react';
import { connect } from 'react-redux';
import './weekdays.css';
class DayWeeksSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: this.props.value
    };
    this.selectDay = this.selectDay.bind(this);
    this.isActive = this.isActive.bind(this);
    this.getDayNumber = this.getDayNumber.bind(this);
    this.orderDays = this.orderDays.bind(this);
  }
  getDayNumber(day) {
    switch (day) {
      case 'Lun': {
        return 0;
      }
      case 'Mar': {
        return 1;
      }
      case 'Mie': {
        return 2;
      }
      case 'Jue': {
        return 3;
      }
      case 'Vie': {
        return 4;
      }
      case 'Sab': {
        return 5;
      }
      case 'Dom': {
        return 6;
      }
      default:
        return 'Lun';
    }
  }
  orderDays(array) {
    return array.sort((a, b) => this.getDayNumber(a) - this.getDayNumber(b));
  }
  selectDay(day) {
    var daysArray = this.state.days.split(',');
    if (daysArray.indexOf(day) >= 0) {
      daysArray.splice(daysArray.indexOf(day), 1);
      this.setState({ days: daysArray.toString() });
      this.props.changeDaySelect(daysArray.toString());
    } else {
      daysArray.push(day);
      this.setState({ days: daysArray.toString() });
      this.props.changeDaySelect(this.orderDays(daysArray).toString());
    }
  }
  isActive(day) {
    var daysArray = this.state.days.split(',');
    if (daysArray.indexOf(day) >= 0) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    return (
      <>
        <ul className="listdays">
          <li
            className={this.isActive('Lun') ? 'active' : null}
            onClick={() => this.selectDay('Lun')}
          >
            L
          </li>
          <li
            className={this.isActive('Mar') ? 'active' : null}
            onClick={() => this.selectDay('Mar')}
          >
            M
          </li>
          <li
            className={this.isActive('Mie') ? 'active' : null}
            onClick={() => this.selectDay('Mie')}
          >
            M
          </li>
          <li
            className={this.isActive('Jue') ? 'active' : null}
            onClick={() => this.selectDay('Jue')}
          >
            J
          </li>
          <li
            className={this.isActive('Vie') ? 'active' : null}
            onClick={() => this.selectDay('Vie')}
          >
            V
          </li>
          <li
            className={this.isActive('Sab') ? 'active' : null}
            onClick={() => this.selectDay('Sab')}
          >
            S
          </li>
          <li
            className={this.isActive('Dom') ? 'active' : null}
            onClick={() => this.selectDay('Dom')}
          >
            D
          </li>
        </ul>
      </>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DayWeeksSelector);
