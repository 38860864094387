import global from "./global";
import * as axios from "axios";

const URL = global.URL;
const headers = {
  Authorization: localStorage.getItem("token"),
};

const GetBanners = async () => {
  try {
    return await axios.get(URL + "/api/banners");
  } catch (error) {
    console.log(error);
  }
};

const SaveBanners = async (data) => {
  try {
    return await axios.post(URL + "/api/banners", data, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

const DeleteBanners = async (id) => {
  try {
    return await axios.delete(URL + "/api/banners?id=" + id, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

const UpdateBanners = async (data, id) => {
  try {
    return await axios.put(URL + "/api/banners/" + id, data, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

const UpdateBannerPostion = async (positionObj, id) => {
  try {
    return await axios.put(URL + "/api/banners/" + id, positionObj, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

export default {
  UpdateBanners,
  DeleteBanners,
  SaveBanners,
  GetBanners,
  UpdateBannerPostion,
};
