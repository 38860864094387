import global from "./global";
import * as axios from "axios";

const URL = global.URL;
const headers = {
  Authorization: localStorage.getItem("token"),
};

const GetSpecialityes = async (page) => {
  try {
    console.log(URL);
    return await axios.get(URL + "/api/speciality?page=" + page);
  } catch (error) {
    console.log(error);
  }
};

const SaveSpeciality = async (data) => {
  console.log(data);
  try {
    return await axios.post(URL + "/api/speciality", data, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

const DeleteSpeciality = async (id) => {
  try {
    return await axios.delete(URL + "/api/speciality?id=" + id, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

const UpdateSpeciality = async (data, id) => {
  try {
    return await axios.put(URL + "/api/speciality?id=" + id, data, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

export default {
  GetSpecialityes,
  SaveSpeciality,
  DeleteSpeciality,
  UpdateSpeciality,
};
