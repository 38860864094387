import global from "./global";
import * as axios from "axios";

// "Country" are in reality "States"

const URL = global.URL;
const GetCountry = () => {
  return axios.get(URL + "/api/country/all");
};
const ActivateCountry = (id, activeObj) => {
  return axios.put(URL + "/api/country/" + id, activeObj);
};
const ActivateCity = (id, activeObj) => {
  return axios.put(URL + "/api/country/city/" + id, activeObj);
};

export default {
  GetCountry,
  ActivateCountry,
  ActivateCity,
};
