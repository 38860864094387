import React, { Component } from 'react';
import DoctorService from '../../../services/doctor.service';

import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';

import Swal from 'sweetalert2';
class PremiumDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      premium: [],
      activePage: 1,
      perpage: 6,
      indexLast: 5,
      indexInitial: 0
    };
    this.isPremium = this.isPremium.bind(this);
  }
  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      indexLast: pageNumber * this.state.perpage - 1,
      indexInitial: (pageNumber - 1) * this.state.perpage
    });
  }
  componentDidMount() {
    this.getPremiumDoctor();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.action !== this.props.action && this.props.action) {
      console.log(
        prevProps.action !== this.props.action,
        prevProps.action,
        this.props.action
      );
      this.getPremiumDoctor();
    }
  }
  com;
  async getPremiumDoctor() {
    var premium = await DoctorService.ListDoctorsPremium('Bolívar');
    if (premium) {
      var array = premium.data.doctors;
      var doctors = [];
      var ids_premium = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].user) {
          doctors.push(array[i]);
          ids_premium.push(array[i]._id);
        }
      }
      if (doctors.length > 0) {
        this.setState({ premium: doctors, ids_premium });
        this.props.setPremium(ids_premium);
      }
    }
  }
  isPremium(id) {
    if (this.props.ids_premium.indexOf(id) >= 0) return true;
    else return false;
  }
  addPremiumDoctor(id) {
    var ids = this.props.ids_premium;
    if (ids.indexOf(id) >= 0) {
      ids.splice(ids.indexOf(id), 1);
      Swal.fire({
        type: 'success',
        title: 'Doctor eliminado de la lista premium',
        showConfirmButton: false,
        timer: 1500
      });
    }

    this.updatePremiumDoctor(ids);
  }
  async updatePremiumDoctor(ids) {
    var premium = await DoctorService.UpdateDoctorsPremium(ids);
    
    if (premium.data.doctors.length > 0) {
      this.getPremiumDoctor();
    } else {
      this.setState({ premium: [] });
    }
    this.props.setPremium(premium.data.doctors);
  }
  render() {
    return (
      <div className="card Recent-Users">
        <div className="card-block px-0 py-3">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Nombre y especialidades</th>
                  <th>Centro médico</th>
                </tr>
              </thead>
              <tbody>
                {this.state.premium.map((data, index) => (
                  <tr key={data._id} className="unread">
                    <td onClick={() => this.addPremiumDoctor(data._id)}>
                      {!this.isPremium(data._id) ? (
                        <i className="feather icon-star"></i>
                      ) : (
                        <i
                          className="feather icon-star-on"
                          style={{ color: '#219148' }}
                        ></i>
                      )}
                    </td>
                    <td style={{ width: '50px' }}>
                      <img
                        className="rounded-circle"
                        style={{ width: '50px', height: '50px' }}
                        src={data.user.profilepic}
                        alt="activity-user"
                      />
                    </td>
                    <td>
                      <h6 className="mb-1">
                        {data.user.firstname} {data.user.lastname}
                      </h6>
                      <p className="m-0">
                        {data.speciality.map((s, is) => (
                          <>
                            {is > 0 ? ', ' : null}
                            {s.name}
                          </>
                        ))}
                      </p>
                    </td>
                    <td>
                      <h6 className="text-muted">
                        {data.consulting_room.map((h, ih) => (
                          <>
                            {ih > 0 ? ', ' : null}
                            {h.hospital ? h.hospital.name : null}
                          </>
                        ))}
                      </h6>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              hideFirstLastPages
              pageRangeDisplayed={this.state.perpage}
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.perpage}
              totalItemsCount={this.state.premium.length}
              onChange={pageNumber => this.handlePageChange(pageNumber)}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ids_premium: state.ids_premium
});
const mapDispatchToProps = dispatch => ({
  setPremium(ids_premium) {
    dispatch({
      type: 'SET_TO_PREMIUM',
      ids_premium: ids_premium
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PremiumDoctor);
