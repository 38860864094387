import React, { Component, createRef } from "react";
import CountryService from "../../../services/country";
import DoctorService from "../../../services/doctor.service";
import HospitalService from "../../../services/hospital.service";
import Swal from "sweetalert2";
import { connect } from "react-redux";

import Pagination from "react-js-pagination";
class Countries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: "",
      inputRef: createRef(),
      activePage: 1,
      perpage: 12,
      indexLast: 11,
      indexInitial: 0,
      namefilter: "",

      cities: [],
      activeState: "Bolívar",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectToEdit = this.selectToEdit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.changeNameFilter = this.changeNameFilter.bind(this);

    this.setActiveCountry = this.setActiveCountry.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.checkDataState = this.checkDataState.bind(this);
  }

  componentDidMount() {}

  // new methods

  async setActiveCountry(country) {
    let activeObj = { active: !country.active };
    let haveDataAssociated = this.checkDataState(country.city);
    if (haveDataAssociated === false) {
      if (country.active === true) {
        try {
          await CountryService.ActivateCountry(country._id, activeObj);
          await this.getCountries();
        } catch (e) {
          Swal.fire({
            type: "error",
            title: `Error de conexión: Intente más tarde`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          type: "error",
          title: `Debe tener al menos una ciudad activa`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      if (country.active === false) {
        try {
          await CountryService.ActivateCountry(country._id, activeObj);
          await this.getCountries();
        } catch (e) {
          console.log(e);
          Swal.fire({
            type: "error",
            title: `Error de conexión: Intente más tarde`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          type: "warning",
          title: `Este estado tiene ciudades activas`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  checkDataState(cities) {
    for (let i = 0; i < cities.length; i++) {
      if (cities[i].active === true) {
        return true;
      }
    }
    return false;
  }

  async setActiveCity(id, active, city) {
    let activeObj = { active: active };
    let haveDataAssociated = await this.checkDataStateCity(city);
    if (haveDataAssociated === false) {
      try {
        await CountryService.ActivateCity(id, activeObj);
        await this.getCountries();
      } catch (e) {
        Swal.fire({
          type: "error",
          title: `Error de conexión: Intente más tarde`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      if (active === true) {
        try {
          await CountryService.ActiveCity(id, activeObj);
          await this.getCountries();
        } catch (e) {
          Swal.fire({
            type: "error",
            title: `Error de conexión: Intente más tarde`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          type: "warning",
          title: `Esta ciudad tiene doctores u hospitales asociados`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  async getCountries() {
    try {
      let countries = await CountryService.GetCountry();
      this.props.setCountries(countries.data);
    } catch (err) {
      console.log(err);
    }
  }

  async checkDataStateCity(city) {
    try {
      let doctors = await DoctorService.ListAllDoctors(-1, city).then((res) => (res.doctors.length > 0 ? true : false));
      let hospitals = await HospitalService.ListHospitals(city).then((res) => (res.hospital.length > 0 ? true : false));
      return hospitals || doctors;
    } catch (err) {
      console.log(err);
    }
  }

  // end new methods

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      indexLast: pageNumber * this.state.perpage - 1,
      indexInitial: (pageNumber - 1) * this.state.perpage,
    });
  }
  handlePageChangeCities(pageNumber) {
    this.setState({
      activePageCities: pageNumber,
      indexLastCities: pageNumber * this.state.perpageCities - 1,
      indexInitialCities: (pageNumber - 1) * this.state.perpageCities,
    });
  }

  handleInputChange(e) {
    this.setState({ speciality: e.target.value });
  }
  selectToEdit(id, speciality, index) {
    this.state.inputRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    this.setState({ speciality: speciality, update: id, index: index });
  }
  changeNameFilter(e) {
    this.setState({ namefilter: e.target.value, activePage: 1 });
  }
  render() {
    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <h5>Listado de estados</h5>
            </div>
            <div className="card-block table-border-style">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "100px" }}>#</th>
                      <th>Nombre del Estado</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.countries.map((data, index) =>
                      this.state.indexInitial <= index && index <= this.state.indexLast ? (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{data.state}</td>
                          <td>
                            <button
                              className="btn-primary"
                              style={{
                                height: "30px",
                                width: "50px",
                                padding: "auto 0px !important",
                                border: "none",
                                borderRadius: "5px",
                                outline: "none !important",
                              }}
                              onClick={() => this.setState({ cities: data.city, activeState: data.state })}
                            >
                              Ver
                            </button>
                          </td>
                          <td className="d-flex align-items-center" style={{ width: "60px", cursor: "pointer", alignItems: "center" }}>
                            <input
                              onClick={() => this.setActiveCountry(data)}
                              class="form-check-input"
                              type="checkbox"
                              id="countrycheck"
                              checked={data.active}
                            />
                            <label class="form-check-label" htmlFor="countrycheck">
                              Activar
                            </label>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </table>
                <Pagination
                  hideFirstLastPages
                  pageRangeDisplayed={6}
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.perpage}
                  totalItemsCount={
                    this.props.countries.filter(
                      (a) => this.state.namefilter === "" || a.name.toLowerCase().includes(this.state.namefilter.toLowerCase())
                    ).length
                  }
                  onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-header">
              <h5>
                Listado de ciudades: <strong>{this.state.activeState}</strong>
              </h5>
            </div>
            <div className="card-block table-border-style">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "100px" }}>#</th>
                      <th>Nombre de la ciudad</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.countries
                      .filter((country) => country.state === this.state.activeState)[0]
                      .city.map((data, index) => (
                        <tr key={data._id}>
                          <th scope="row">{index + 1}</th>
                          <td>{data.city}</td>
                          <td style={{ width: "60px", cursor: "pointer" }}>
                            <input
                              key={data._id}
                              class="form-check-input"
                              type="checkbox"
                              id="countrycheck"
                              value={data.active}
                              checked={data.active}
                              onChange={async () => {
                                await this.setActiveCity(data._id, !data.active, data.city);
                              }}
                            />

                            <label class="form-check-label" htmlFor="countrycheck">
                              Activar
                            </label>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialities: state.specialities,
  countries: state.countries,
  toastr: state.toastr,
});
const mapDispatchToProps = (dispatch) => ({
  setCountries(value) {
    dispatch({
      type: "FETCH_COUNTRIES",
      value: value,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Countries);
