import global from "./global";
import * as axios from "axios";

const URL = global.URL;
const headers = {
  Authorization: localStorage.getItem("token"),
};

const GetService_categories = async (page) => {
  try {
    console.log(URL);
    return await axios.get(URL + "/api/service_category");
  } catch (error) {
    console.log(error);
  }
};

const SaveServiceCategory = (service_category) => {
  try {
    return axios.post(URL + "/api/service_category", service_category, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

const UpdateServiceCategory = async (service_category, id) => {
  try {
    return await axios.put(URL + "/api/service_category/" + id, service_category, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

const DeleteServiceCategory = async (id) => {
  try {
    return await axios.delete(URL + "/api/service_category/" + id, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

export default {
  GetService_categories,
  SaveServiceCategory,
  UpdateServiceCategory,
  DeleteServiceCategory,
};
