import global from "./global";
import * as axios from "axios";

const URL = global.URL;
const headers = {
  Authorization: localStorage.getItem("token"),
};
async function ListAllDoctors(page, city, hospital) {
  try {
    return await axios.get(URL + "/api/doctor/all?page=" + page + "&city=" + city).then((response) => {
      return response.data;
    });
  } catch (error) {
    console.log(error);
  }
}
async function ListEmergencyDoctors(page, city, hospital) {
  try {
    return await axios.get(URL + "/api/doctor/attend_emergencies_city?page=" + page + "&city=" + city).then((response) => {
      return response.data;
    });
  } catch (error) {
    console.log(error);
  }
}
async function ListAllInactiveDoctors(page, city, hospital) {
  try {
    return await axios.get(URL + "/api/doctor/all/inactive?page=" + page + "&city=" + city).then((response) => {
      return response.data;
    });
  } catch (error) {
    console.log(error);
  }
}

async function changeStatus(id) {
  try {
    return await axios.put(URL + "/api/doctor/status?id=" + id, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
}

async function SaveDoctor(data, user) {
  try {
    return await axios.put(URL + "/api/doctor?user=" + user, data, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
}

async function ListDoctorsPremium(state) {
  try {
    return await axios.get(URL + "/api/doctor/premium?state=" + state);
  } catch (error) {
    console.log(error);
  }
}

async function UpdateDoctorsPremium(ids) {
  try {
    return await axios.put(
      URL + "/api/doctor/premium",
      {
        doctors: ids,
      },
      {
        headers,
      }
    );
  } catch (error) {
    console.log(error);
  }
}

const UpdateAttendEmergenciesDoctor = async (attend_emergency, id) => {
  try {
    return await axios.put(URL + "/api/doctor/emergencies/" + id, attend_emergency, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

export default {
  ListAllDoctors,
  changeStatus,
  SaveDoctor,
  ListDoctorsPremium,
  UpdateDoctorsPremium,
  ListAllInactiveDoctors,
  UpdateAttendEmergenciesDoctor,
  ListEmergencyDoctors,
};
