import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import Services_categories from "./services_categories";
import Services from "./services";
const { TabPane } = Tabs;

class ServiceTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: [],
      city: "Puerto Ordaz",
      index: 0,
      type: "1",
      update: null,
    };
  }

  render() {
    return (
      <>
        <Tabs defaultActiveKey="1" onChange={(e) => this.setState({ type: e })}>
          <TabPane tab={"Categorías"} key="1">
            <Services_categories />
          </TabPane>
          <TabPane tab="Servicios" key="2">
            <Services />
          </TabPane>
        </Tabs>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTabs);
