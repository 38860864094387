import React, { Component, createRef } from "react";
import ServicesService from "../../../services/services.service";
import Swal from "sweetalert2";
import { connect } from "react-redux";

import Pagination from "react-js-pagination";
import { Popconfirm } from "antd";
class Specialities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: "",
      index: "",
      inputRef: createRef(),
      activePage: 1,
      perpage: 6,
      indexLast: 5,
      indexInitial: 0,
      namefilter: "",
      inSave: false,

      services: [],
      service_name: "",
      service_direction: "",
      tlf1: "",
      tlf2: "",
      service_category_id: "",
      images: [],
      toUpdate: false,
      idToUpdate: "",
      show: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectToEdit = this.selectToEdit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.changeNameFilter = this.changeNameFilter.bind(this);
    //New
    this.SaveService = this.SaveService.bind(this);
    this.UpdateService = this.UpdateService.bind(this);

    this.DeleteService = this.DeleteService.bind(this);
    this.getServices = this.getServices.bind(this);
  }

  // New Mthods
  SaveService() {
    let service = {
      name: this.state.service_name,
      direction: this.state.service_direction,
      tlf1: this.state.tlf1,
      tlf2: this.state.tlf2,
      category: this.state.service_category_id,
      images: this.state.images,
    };

    ServicesService.SaveService(service)
      .then(async (res) => {
        Swal.fire({
          type: "success",
          title: `Servicio ${res.data.name} agregado`,
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getServices();
      })
      .catch((err) =>
        Swal.fire({
          type: "error",
          title: `Complete los campos requeridos (Nombre, Tlf 1 y Categoría)`,
          showConfirmButton: false,
          timer: 1500,
        })
      );
  }

  selectToEdit(service) {
    this.state.inputRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    this.setState({
      service_name: service.name,
      service_direction: service.direction,
      tlf1: service.tlf1,
      tlf2: service.tlf2,
      service_category_id: service.category._id,
      images: service.images,
      idToUpdate: service._id,
      toUpdate: true,
    });
  }

  async UpdateService(service_id) {
    let service_to_update = {
      name: this.state.service_name,
      direction: this.state.service_direction,
      tlf1: this.state.tlf1,
      tlf2: this.state.tlf2,
      category: this.state.service_category_id,
    };

    try {
      let service_updated = await ServicesService.UpdateService(service_to_update, service_id);
      if (service_updated) {
        this.setState({
          toUpdate: false,
          service_name: "",
          service_direction: "",
          tlf1: "",
          tlf2: "",
          images: [""],
          service_category_id: "",
        });
      }
      Swal.fire({
        type: "success",
        title: `Servicio actualizado`,
        showConfirmButton: false,
        timer: 1500,
      });

      await this.getServices();
    } catch (err) {
      console.log(err);
    }
  }

  async DeleteService(service_id) {
    let service = await ServicesService.DeleteService(service_id);
    if (service) {
      Swal.fire({
        type: "success",
        title: "Servicio eliminado",
        showConfirmButton: false,
        timer: 1500,
      });
      await this.getServices();
    }
  }

  async getServices() {
    try {
      let services = await ServicesService.GetServices();
      this.props.setServices(services.data);
    } catch (err) {
      console.log(err);
    }
  }

  // EndNewMethods

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      indexLast: pageNumber * this.state.perpage - 1,
      indexInitial: (pageNumber - 1) * this.state.perpage,
    });
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  }

  changeNameFilter(e) {
    this.setState({ namefilter: e.target.value, activePage: 1 });
  }
  render() {
    return (
      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          <button className="btn btn-primary mb-3" onClick={() => this.setState({ show: !this.state.show })}>
            Añadir Servicio
          </button>
        </div>
        {this.state.show ? (
          <>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5>Servicios</h5>
                </div>
                <div className="card-body" ref={this.state.inputRef}>
                  <form>
                    <div className="form-group">
                      <label>Nombre del servicio</label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        name="service_name"
                        placeholder="Indica el nombre del servicio"
                        onChange={this.handleInputChange}
                        value={this.state.service_name}
                        required
                      />
                      <label>Dirección del servicio</label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        name="service_direction"
                        placeholder="Indica la dirección del servicio"
                        onChange={this.handleInputChange}
                        value={this.state.service_direction}
                      />
                      <label>Tlf 1</label>
                      <input
                        type="tel"
                        className="form-control mb-1"
                        name="tlf1"
                        placeholder="Agregue un número de tlf"
                        onChange={this.handleInputChange}
                        value={this.state.tlf1}
                        required
                      />
                      <label>Tlf 2</label>
                      <input
                        type="tel"
                        className="form-control mb-1"
                        name="tlf2"
                        placeholder="Agregue un número de tlf secundario"
                        onChange={this.handleInputChange}
                        value={this.state.tlf2}
                      />
                      {/* Make a select options from service_categories */}
                      <label>Categoría</label>
                      <select
                        className="form-control"
                        name="service_category_id"
                        onChange={this.handleInputChange}
                        value={this.state.service_category_id}
                      >
                        <option value="">Seleccione una categoría</option>
                        {this.props.service_categories.map(function (service_category, index) {
                          return (
                            <option key={`index-${service_category._id}`} value={service_category._id}>
                              {service_category.name}
                            </option>
                          );
                        })}
                      </select>

                      {/* Input to add image, the database and state is ready to acept it                
                <label>Imágenes para la categoría</label>
                <input
                  type="file"
                  name="images"
                  className="form-control"
                /> 
*/}
                    </div>
                  </form>

                  {this.state.toUpdate ? (
                    <button type="submit" onClick={() => this.UpdateService(this.state.idToUpdate)} className="btn btn-primary">
                      Editar Servicio
                    </button>
                  ) : (
                    <button type="submit" onClick={this.SaveService} className="btn btn-primary">
                      Guardar Servicio
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div className="row d-flex flex-row">
                <h5 className="col-9">Listado de servicios</h5>
                <div className="filterforms col-3 justify-content-end">
                  <div className="form-group">
                    <input value={this.state.namefilter} onChange={this.changeNameFilter} className="form-control" placeholder="Buscar"></input>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "100px" }}>#</th>
                      <th>Nombre del servicio</th>
                      <th>tlf 1</th>
                      <th>tlf 2</th>
                      <th>Categoría</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.services
                      .filter(
                        (a) =>
                          this.state.namefilter === "" ||
                          a.name
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/\p{Diacritic}/gu, "")
                            .includes(
                              this.state.namefilter
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/\p{Diacritic}/gu, "")
                            )
                      )
                      .map((service, index) =>
                        this.state.indexInitial <= index && index <= this.state.indexLast ? (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{service.name}</td>
                            <td>{service.tlf1}</td>
                            <td>{service.tlf2}</td>
                            <td>{service.category.name}</td>
                            <td style={{ width: "60px", cursor: "pointer" }}>
                              <i
                                onClick={async () => {
                                  await this.setState({ show: true });
                                  this.selectToEdit(service);
                                }}
                                className="feather icon-edit-2"
                              ></i>
                            </td>
                            <td style={{ width: "60px", cursor: "pointer" }}>
                              <Popconfirm
                                placement="top"
                                title={"¿Realmente desea eliminar esta especialidad?"}
                                onConfirm={() => this.DeleteService(service._id)}
                                okText="Si claro"
                                cancelText="No"
                              >
                                <i className="feather icon-trash-2"></i>
                              </Popconfirm>
                            </td>
                          </tr>
                        ) : null
                      )}
                  </tbody>
                </table>
                <Pagination
                  hideFirstLastPages
                  pageRangeDisplayed={6}
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.perpage}
                  totalItemsCount={
                    this.props.services.filter(
                      (a) => this.state.namefilter === "" || a.name.toLowerCase().includes(this.state.namefilter.toLowerCase())
                    ).length
                  }
                  onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  toastr: state.toastr,
  services: state.services,
  service_categories: state.service_categories,
});
const mapDispatchToProps = (dispatch) => ({
  setServices(value) {
    dispatch({
      type: "FETCH_SERVICES",
      value: value,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Specialities);
