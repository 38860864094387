import { createStore } from "redux";
import update from "react-addons-update";

const initialState = {
  page: "doctors",
  specialities: [],
  showMenu: false,
  ids_premium: JSON.parse(localStorage.getItem("premium")) || [],
  service_categories: [],
  services: [],
  countries: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_PAGE": {
      return update(state, {
        page: {
          $set: action.page,
        },
        showMenu: {
          $set: false,
        },
      });
    }

    case "CHANGE_SPECIALITY": {
      return update(state, {
        specialities: {
          $set: action.value,
        },
      });
    }

    case "FETCH_SERVICE_CATEGORIES": {
      return update(state, {
        service_categories: {
          $set: action.value,
        },
      });
    }

    case "FETCH_SERVICES": {
      return update(state, {
        services: {
          $set: action.value,
        },
      });
    }
    case "FETCH_COUNTRIES": {
      return update(state, {
        countries: {
          $set: action.value,
        },
      });
    }

    case "ADD_TO_LIST": {
      return {
        ...state,
        specialities: state.specialities.concat([action.data]),
      };
    }
    case "DELETE_TO_LIST": {
      return update(state, {
        specialities: {
          $splice: [[action.index, 1]],
        },
      });
    }
    case "SHOW_MENU": {
      return update(state, {
        showMenu: {
          $set: !state.showMenu,
        },
      });
    }
    case "SET_TO_PREMIUM": {
      localStorage.setItem("premium", JSON.stringify(action.ids_premium));
      return update(state, {
        ids_premium: {
          $set: action.ids_premium,
        },
      });
    }
    default:
      return {
        ...state,
      };
  }
};

export default createStore(reducer);
