import React, { Component } from 'react';
import DoctorService from '../../../services/doctor.service';
import UserService from '../../../services/user.service';
import HospitalService from '../../../services/hospital.service';
import DayWeeksSelector from './weekdays';
import { Modal } from 'react-bootstrap';

import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Steps, Select, TimePicker, Tag, Checkbox } from 'antd';
import moment from 'moment';
import global from '../../../services/global';

const { Step } = Steps;
const stepStyle = {
  marginBottom: 60,
  boxShadow: '0px -1px 0 0 #e8e8e8 inset',
};

class DoctorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      /*  */
      firstname: '',
      lastname: '',
      sex: 0,
      phone: '',
      phone_consult: '',
      profilepic: '',
      role: 'doctor',
      password: 'doctordefaultpass2019',
      email: '',
      speciality: [],
      consulting_room: [],
      /*  */
      hospitals: [],
      hospitals_name: [],
      hospital: '',
      direction: '',
      schedules: [],
      week_type: 'flexible',
      package: '',
      whatsapp: '',
      web: '',
      facebook: '',
      facebooknickname: '',
      instagram: '',
      instagramnickname: '',
      twitter: '',
      twitternickname: '',
      youtube: '',
      youtubenickname: '',
      linkedin: '',
      linkedinnickname: '',
      tiktok: '',
      ticktoknickname: '',
      days: 'Lun,Mar,Mie,Jue,Vie,Sab,Dom',
      tagstime: [],
      city: 'Puerto Ordaz',
      activeState: 'Bolívar',
      index: 0,
      time_start: '08:00 am',
      time_end: '12:00 pm',
      cita: false,
      show: false,
      preview: null,
      src: null,
      inSave: false,
    };
    this.changeSteps = this.changeSteps.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changeState = this.changeState.bind(this);
    this.agregateTagTime = this.agregateTagTime.bind(this);
    this.handleInputTimeChange = this.handleInputTimeChange.bind(this);
    this.handleInputTimeEndChange = this.handleInputTimeEndChange.bind(this);
    this.changeDaySelects = this.changeDaySelects.bind(this);
    this.agregateSchedules = this.agregateSchedules.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.deleteshcedule = this.deleteshcedule.bind(this);
    this.handleInputHospitalChange = this.handleInputHospitalChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.addToConsultingRoom = this.addToConsultingRoom.bind(this);
    this.deleteToConsultingRomm = this.deleteToConsultingRomm.bind(this);
    this.getNameById = this.getNameById.bind(this);
    this.validateData = this.validateData.bind(this);
    this.saveDoctor = this.saveDoctor.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCrop = this.onCrop.bind(this);
    this.close = this.close.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
  }
  componentDidMount() {
    this.getHospitals(this.state.city);
    if (this.props.updateUser) {
      this.setState({
        firstname: this.props.updateUser.user.firstname,
        lastname: this.props.updateUser.user.lastname,
        phone: this.props.updateUser.user.phone,
        phone_consult: this.props.updateUser.user.phone_consult,
        sex: this.props.updateUser.user.sex,
        profilepic: this.props.updateUser.user.profilepic,
        src: this.props.updateUser.user.profilepic,
        role: 'doctor',
        package: this.props.updateUser.package,
        services: this.props.updateUser.services,
        password: 'doctordefaultpass2019',
        whatsapp: this.props.updateUser.user.whatsapp,
        web: this.props.updateUser.user.web,
        facebook: this.props.updateUser.user.facebook.url,
        facebooknickname: this.props.updateUser.user.facebook.nickname,
        instagram: this.props.updateUser.user.instagram.url,
        instagramnickname: this.props.updateUser.user.instagram.nickname,
        twitter: this.props.updateUser.user.twitter.url,
        twitternickname: this.props.updateUser.user.twitter.nickname,
        youtube: this.props.updateUser.user.youtube.url,
        youtubenickname: this.props.updateUser.user.youtube.nickname,
        linkedin: this.props.updateUser.user.linkedin.url,
        linkedinnickname: this.props.updateUser.user.linkedin.nickname,
        tiktok: this.props.updateUser.user.tiktok.url,
        tiktoknickname: this.props.updateUser.user.tiktok.nickname,
        email: this.props.updateUser.user.email,
        speciality: this.props.updateUser.speciality,
      });
      var consulting = [];
      for (let i = 0; i < this.props.updateUser.consulting_room.length; i++) {
        const element = this.props.updateUser.consulting_room[i];
        consulting.push({
          schedules: element.schedules,
          hospital: element.hospital._id,
          direction: element.direction,
        });
      }
      this.setState({
        consulting_room: consulting,
      });
    }
  }
  changeSteps(current) {
    this.setState({ current });
  }
  changeSpecialitys(value) {
    this.setState({ speciality: value });
  }
  async getHospitals(city) {
    var hospitals = await HospitalService.ListHospitals(city);
    if (hospitals.hospital.length > 0) {
      this.setState({ hospital: hospitals.hospital[0]._id });
      this.setState({ hospitals: hospitals.hospital });
    } else if (hospitals.hospital.length === 0) {
      this.setState({ hospital: '' });
      this.setState({ hospitals: [] });
    }
  }
  changeState(event) {
    this.setState({
      activeState: event.target.value,
      index: event.target.value,
      city: this.props.country.filter((state) => state.state === event.target.value)[0].city[0].city,
    });
    this.getHospitals(this.props.country.filter((state) => state.state === event.target.value)[0].city[0].city);
  }
  changeCity(event) {
    this.setState({ city: event.target.value });
    this.getHospitals(event.target.value);
  }
  agregateTagTime() {
    if (this.state.tagstime.length < 3) {
      var times = this.state.tagstime;
      times.push(this.state.time_start + ' - ' + this.state.time_end);
      this.setState({ tagstime: times });
    }
  }
  handleInputTimeChange(time, timeString) {
    var value = timeString;
    this.setState({ time_start: value });
  }
  handleInputTimeEndChange(time, timeString) {
    var value = timeString;
    this.setState({ time_end: value });
  }
  changeDaySelects(e, index) {
    this.setState({ days: e });
  }
  addToConsultingRoom() {
    if (this.state.schedules.length <= 0) {
      alert('No ha registrado ningun horario');
    } else {
      var consulting_room = this.state.consulting_room;
      consulting_room.push({
        schedules: this.state.schedules,
        hospital: this.state.hospital,
        direction: this.state.direction,
      });
      var hospitals_name = this.state.hospitals_name;
      hospitals_name.push({
        id: this.state.hospital,
        name: this.getHospitalname(this.state.hospital),
      });
      this.setState({
        consulting_room,
        schedules: [],
        hospital: '',
        hospitals_name: hospitals_name,
      });
    }
  }
  getHospitalname(hospital) {
    for (let i = 0; i < this.state.hospitals.length; i++) {
      if (hospital === this.state.hospitals[i]._id) {
        return this.state.hospitals[i].name;
      }
    }
  }
  getNameById(id) {
    if (this.props.updateUser) {
      var consulting_room = this.props.updateUser.consulting_room;
      for (let i = 0; i < consulting_room.length; i++) {
        if (id === consulting_room[i].hospital._id) {
          return consulting_room[i].hospital.name;
        } else {
          for (let i = 0; i < this.state.hospitals_name.length; i++) {
            if (id === this.state.hospitals_name[i].id) {
              return this.state.hospitals_name[i].name;
            }
          }
        }
      }
    } else {
      for (let i = 0; i < this.state.hospitals_name.length; i++) {
        if (id === this.state.hospitals_name[i].id) {
          return this.state.hospitals_name[i].name;
        } else {
        }
      }
    }
  }
  deleteToConsultingRomm(id) {
    var consulting_room = this.state.consulting_room;
    consulting_room.splice(id, 1);
    this.setState({ consulting_room });
  }

  agregateSchedules() {
    var timeString = this.state.tagstime.toString();
    var schedule = {
      days: this.state.cita ? '' : this.state.days,
      time: this.state.cita ? 'Previa cita' : timeString,
      week_type: this.state.cita ? '' : this.state.week_type,
    };
    var schedules = this.state.schedules;
    schedules.push(schedule);
    this.setState({ schedules });
    this.handleClose();
  }

  handleClose() {
    this.setState({
      show: false,
      cita: false,
      tagstime: [],
      time_start: '08:00 am',
      time_end: '12:00 pm',
    });
  }
  handleShow() {
    if (this.state.schedules.length < 3) {
      this.setState({ show: true });
    } else {
    }
  }
  deleteshcedule(id) {
    var schedules = this.state.schedules;
    schedules.splice(id, 1);
    this.setState({ schedules });
  }
  handleInputHospitalChange(e) {
    var value = e.target.value;
    this.setState({ hospital: value });
  }
  handleInputChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  }
  validateData() {
    if (
      /* this.state.profilepic !== null &&
      this.state.profilepic !== "" && */
      this.state.firstname !== '' &&
      this.state.speciality.length > 0 &&
      this.state.consulting_room.length > 0
    )
      return true;
    else return false;
  }
  onClose() {
    this.setState({ preview: null });
  }

  onCrop(preview) {
    this.setState({ preview });
  }
  dayExtend(day) {
    switch (day) {
      case 'Lun':
        return 'Lunes';
      case 'Mar':
        return 'Martes';
      case 'Mie':
        return 'Miércoles';
      case 'Jue':
        return 'Jueves';
      case 'Vie':
        return 'Viernes';
      case 'Sab':
        return 'Sábado';
      case 'Dom':
        return 'Domingo';
      default:
        return '';
    }
  }
  showDaysExtend(days) {
    days = days.split(',');
    if (days[0] === '') {
      days.splice(0, 1);
      days = days.toString();
    }
    switch (days.toString()) {
      case 'Lun,Mar,Mie,Jue,Vie,Sab,Dom':
        return 'Lunes a Domingo';
      case 'Lun,Mar,Mie,Jue,Vie,Sab':
        return 'Lunes a Sábado';
      case 'Lun,Mar,Mie,Jue,Vie':
        return 'Lunes a Viernes';
      case 'Lun,Mar,Mie,Jue':
        return 'Lunes a Jueves';
      case 'Lun,Mar,Mie':
        return 'Lunes a Miércoles';
      case 'Lun,Mar':
        return 'Lunes y Martes';
      case 'Mar,Mie':
        return 'Martes y Miércoles';
      case 'Mar,Mie,Jue':
        return 'Martes a Jueves';
      case 'Mar,Mie,Jue,Vie':
        return 'Martes a Viernes';
      case 'Mar,Mie,Jue,Vie,Sab':
        return 'Martes a Sábado';
      case 'Mar,Mie,Jue,Vie,Sab,Dom':
        return 'Martes a Domingo';
      case 'Mie,Jue':
        return 'Miércoles y Jueves';
      case 'Mie,Jue,Vie':
        return 'Miércoles a Viernes';
      case 'Mie,Jue,Vie,Sab':
        return 'Miércoles a Sábado';
      case 'Mie,Jue,Vie,Sab,Dom':
        return 'Miércoles a Domingo';
      case 'Jue,Vie':
        return 'Jueves a Viernes';
      case 'Jue,Vie,Sab':
        return 'Jueves a Sábado';
      case 'Jue,Vie,Sab,Dom':
        return 'Jueves a Domingo';
      case 'Vie,Sab':
        return 'Viernes y Sábado';
      case 'Vie,Sab,Dom':
        return 'Viernes a Domingo';
      default: {
        let d = days.toString();
        const daysarray = d.split(',');
        var daytext = '';
        for (let i = 0; i < daysarray.length; i++) {
          if (i === daysarray.length - 1 && i > 0) {
            daytext += ' y ';
          } else if (i !== 0) daytext += ', ';
          daytext += this.dayExtend(daysarray[i]);
        }
        return daytext;
      }
    }
  }
  async saveDoctor() {
    this.setState({ inSave: true });
    if (!this.props.updateUser) {
      if (this.validateData()) {
        let user = await UserService.SaveUser({
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          phone: this.state.phone,
          phone_consult: this.state.phone_consult,
          sex: this.state.sex,
          profilepic: this.state.profilepic,
          role: this.state.role,
          whatsapp: this.state.whatsapp,
          password: this.state.password,
          email: this.state.email,
          web: this.state.web,
          facebook: {
            url: this.state.facebook,
            nickname: this.state.facebooknickname,
          },
          instagram: {
            url: this.state.instagram,
            nickname: this.state.instagramnickname,
          },
          twitter: {
            url: this.state.twitter,
            nickname: this.state.twitternickname,
          },
          youtube: {
            url: this.state.youtube,
            nickname: this.state.youtubenickname,
          },
          linkedin: {
            url: this.state.linkedin,
            nickname: this.state.linkedinnickname,
          },
          tiktok: {
            url: this.state.tiktok,
            nickname: this.state.tiktoknickname,
          },
        });
        if (user) {
          let doctor = await DoctorService.SaveDoctor(
            {
              speciality: this.state.speciality,
              services: this.state.services,
              package: this.state.package,
              consulting_room: this.state.consulting_room,
            },
            user.data.user._id
          );
          if (doctor.data) {
            this.close(true);
            this.setState({ inSave: false });
            Swal.fire({
              type: 'success',
              title: 'Doctor Agregado',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } else {
        this.setState({ inSave: false });
        Swal.fire({
          type: 'warning',
          title: 'Debe rellenar todos los campos',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      if (this.validateData()) {
        let user = await UserService.UpdateUSer(
          {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            phone: this.state.phone,
            phone_consult: this.state.phone_consult,
            profilepic: this.state.profilepic,
            role: this.state.role,
            sex: this.state.sex,
            password: this.state.password,
            whatsapp: this.state.whatsapp,
            web: this.state.web,
            facebook: {
              url: this.state.facebook,
              nickname: this.state.facebooknickname,
            },
            instagram: {
              url: this.state.instagram,
              nickname: this.state.instagramnickname,
            },
            twitter: {
              url: this.state.twitter,
              nickname: this.state.twitternickname,
            },
            youtube: {
              url: this.state.youtube,
              nickname: this.state.youtubenickname,
            },
            linkedin: {
              url: this.state.linkedin,
              nickname: this.state.linkedinnickname,
            },
            tiktok: {
              url: this.state.tiktok,
              nickname: this.state.tiktoknickname,
            },
            email: this.state.email,
          },
          this.props.updateUser.user._id
        );
        if (user) {
          let doctor = await DoctorService.SaveDoctor(
            {
              speciality: this.state.speciality,
              services: this.state.services,
              package: this.state.package,
              consulting_room: this.state.consulting_room,
            },
            user.data.user._id
          );
          if (doctor.data) {
            this.close(true);
            this.setState({ inSave: false });
            Swal.fire({
              type: 'success',
              title: 'Doctor actualizado',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } else {
        this.setState({ inSave: false });
        Swal.fire({
          type: 'warning',
          title: 'Debe rellenar todos los campos',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }
  async handleInputFileChange(e) {
    const formData = new FormData();
    formData.append('imagen', e.target.files[0]);
    var filesave = await UserService.UploadPic(formData);
    console.log(e.target);
    if (filesave) {
      this.setState({
        profilepic: global.URL + '/api/file?file=' + filesave.data.imagen,
      });
    }
  }
  clearState() {
    this.setState({
      show: false,
      cita: false,
      tagstime: [],
      time_start: '08:00 am',
      time_end: '12:00 pm',
      firstname: '',
      lastname: '',
      phone: '',
      phone_consult: '',
      profilepic: '',
      role: 'doctor',
      password: 'doctordefaultpass2019',
      email: '',
      whatsapp: '',
      web: '',
      facebook: '',
      facebooknickname: '',
      instagram: '',
      instagramnickname: '',
      twitter: '',
      twitternickname: '',
      youtube: '',
      youtubenickname: '',
      linkedin: '',
      linkedinnickname: '',
      tiktok: '',
      ticktoknickname: '',
      sex: 0,
      speciality: [],
      consulting_room: [],
      week_type: '',
    });
  }
  close(e) {
    this.clearState();
    this.props.onClose({ update: e });
  }

  showWeekType(week_type) {
    let middleWare = {
      flexible: 'Ninguna',
      flexible1: 'Flexible',
      radical: 'Radical',
    };

    return middleWare[week_type];
  }

  render() {
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose} size="lg">
          <Modal.Body>
            <div className="container">
              <div>
                <label>Dias de trabajo</label>
                <DayWeeksSelector value={this.state.days} name="days" changeDaySelect={(e) => this.changeDaySelects(e, 0)} />
              </div>
              <div>
                <Checkbox className="col-12" defaultValue={this.state.cita} onChange={() => this.setState({ cita: !this.state.cita })}>
                  Previa cita
                </Checkbox>
              </div>
              <div className="row">
                {!this.state.cita ? (
                  <>
                    <div className="col-12">
                      {this.state.tagstime.map((tg, index) => (
                        <Tag key={index} color="#0a4785" closable>
                          {tg}
                        </Tag>
                      ))}
                    </div>
                    <div className="form-group col-3" style={{ display: 'grid' }}>
                      <label>Hora de inicio</label>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        name="time_start"
                        minuteStep={15}
                        value={moment(this.state.time_start, 'HH:mm a')}
                        onChange={this.handleInputTimeChange}
                      />
                    </div>
                    <div className="form-group col-3" style={{ display: 'grid' }}>
                      <label>Hora final</label>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        name="time_end"
                        minuteStep={15}
                        value={moment(this.state.time_end, 'HH:mm a')}
                        onChange={this.handleInputTimeEndChange}
                      />
                    </div>
                    <div className="form-group col-3" style={{ display: 'grid' }}>
                      <label>Tipo de semana</label>
                      {/* EL tipo de semana flexible es para "semana normal" y flexible1 para "semana flexible" */}
                      <div>
                        <Checkbox
                          className="col-12"
                          value={'flexible'}
                          checked={this.state.week_type === 'flexible' ? true : false}
                          onChange={(e) => this.setState({ week_type: e.target.value })}
                        >
                          Ninguna
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          className="col-12"
                          value={'flexible1'}
                          checked={this.state.week_type === 'flexible1' ? true : false}
                          onChange={(e) => this.setState({ week_type: e.target.value })}
                        >
                          Flexible
                        </Checkbox>
                      </div>
                      <div>
                        <Checkbox
                          className="col-12"
                          value={'radical'}
                          checked={this.state.week_type === 'radical' ? true : false}
                          onChange={(e) => {
                            this.setState({ week_type: e.target.value });
                          }}
                        >
                          Radical
                        </Checkbox>
                      </div>{' '}
                    </div>
                    <div
                      className="col-3"
                      style={{
                        display: 'flex',
                        marginBottom: '1rem',
                        alignItems: 'flex-end',
                      }}
                    >
                      <button className="btn btn-info" onClick={this.agregateTagTime} style={{ width: '100%', margin: '0' }}>
                        Agregar hora
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secundary" onClick={this.handleClose}>
              Cancelar
            </button>
            <button className="btn btn-primary" onClick={this.agregateSchedules} disabled={!this.state.cita && this.state.tagstime.length <= 0}>
              Guardar
            </button>
          </Modal.Footer>
        </Modal>
        <div className="card" style={{ boxShadow: '0 1px 20px 0 rgba(69, 90, 100, 0.4)' }}>
          <div className="card-block px-0 py-3">
            <Steps type="navigation" size="small" current={this.state.current} onChange={this.changeSteps} style={stepStyle}>
              <Step status={this.state.current === 0 ? 'process' : 'wait'} title="Datos personales" />
              <Step status={this.state.current === 1 ? 'process' : 'wait'} title="Redes Sociales" />
              <Step status={this.state.current === 2 ? 'process' : 'wait'} title="Datos profesionales" />
              <Step status={this.state.current === 3 ? 'process' : 'wait'} title="Datos del Consultorio" />
            </Steps>
            {this.state.current === 0 ? (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {/*  <Avatar
                      height={200}
                      width={200}
                      onCrop={this.onCrop}
                      onClose={this.onClose}
                      src={this.state.src}
                      label="Imagen de perfil"
                      imageWidth={200}
                      imageHeight={200}
                    />
                    {this.state.preview !== '' ?<img src={this.state.preview} alt="img" style={{width:'80px',margin:'20px'}}></img>:null} */}
                    <input type="file" className="form-control" onChange={this.handleInputFileChange} />
                    {this.state.profilepic !== '' ? (
                      <img src={this.state.profilepic} alt="profilepic" style={{ width: '20%' }}></img>
                    ) : (
                      <div>No se ha subido una foto</div>
                    )}
                  </div>
                  <div className="row col-12">
                    <div className="form-group col-6">
                      <label>Nombre</label>
                      <input
                        type="text"
                        name="firstname"
                        value={this.state.firstname}
                        className="form-control"
                        placeholder="Indica el nombre del doctor"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>Apellido</label>
                      <input
                        type="text"
                        name="lastname"
                        value={this.state.lastname}
                        className="form-control"
                        placeholder="Indica el apellido del doctor"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>Género</label>
                      <select className="form-control" name="sex" id="sexform" onChange={this.handleInputChange} value={this.state.sex}>
                        <option value={0}>Sin definir</option>
                        <option value={1}>Masculino</option>
                        <option value={2}>Femenino</option>
                        <option value={3}>FT</option>
                        <option value={4}>LCDO</option>
                        <option value={5}>LCDA</option>
                        <option value={6}>ESP</option>
                        <option value={7}>Msc</option>
                        <option value={8}>Od</option>
                      </select>
                    </div>
                    <div className="form-group col-6">
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={this.state.phone}
                        name="phone"
                        className="form-control"
                        placeholder="Indica el número telefonico"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>Número del consultorio</label>
                      <input
                        type="text"
                        value={this.state.phone_consult}
                        name="phone_consult"
                        className="form-control"
                        placeholder="Indica el número telefonico del consultorio"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>Whatsapp</label>
                      <input
                        type="text"
                        value={this.state.whatsapp}
                        name="whatsapp"
                        className="form-control"
                        placeholder="Indica el número de whatsapp"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>Correo electrónico</label>
                      <input
                        type="text"
                        value={this.state.email}
                        name="email"
                        className="form-control"
                        placeholder="Indica el correo electrónico"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.current === 1 ? (
              <div className="container">
                <div className="row">
                  <div className="form-group col-6">
                    <label>Página web</label>
                    <input
                      type="text"
                      value={this.state.web}
                      name="web"
                      className="form-control"
                      placeholder="Indica la página web"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Facebook</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.facebooknickname}
                          name="facebooknickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.facebook}
                          name="facebook"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Twitter</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.twitternickname}
                          name="twitternickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.twitter}
                          name="twitter"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Instagram</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.instagramnickname}
                          name="instagramnickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.instagram}
                          name="instagram"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Youtube</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.youtubenickname}
                          name="youtubenickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.youtube}
                          name="youtube"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Instagram 2</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.linkedinnickname}
                          name="linkedinnickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.linkedin}
                          name="linkedin"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>TikTok</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.tiktoknickname}
                          name="tiktoknickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.tiktok}
                          name="tiktok"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.current === 2 ? (
              <div className="container">
                <div className="form-group">
                  <label>Especialidades</label>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    name="speciality"
                    placeholder="Selecciona las especialidades"
                    defaultValue={this.state.speciality.map((speciality) => (this.props.updateUser ? speciality._id : speciality))}
                    onChange={this.changeSpecialitys.bind(this)}
                    filterOption={(input, option) => {
                      let optionList = option.props.children.normalize('NFD')
                      .replace(/\p{Diacritic}/gu, '')
                      .toLowerCase()
                      .includes(
                        input
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/\p{Diacritic}/gu, '')
                      );
                      return optionList
                    }}
                  >
                    {this.props.specialities.map((speciality, index) => (
                      <Select.Option value={speciality._id} key={`S-${speciality._id}`}>
                        {speciality.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="form-group">
                  <label>Servicios</label>
                  <input
                    type="text"
                    value={this.state.services}
                    name="services"
                    className="form-control"
                    placeholder="Indica los servicios que ofrece separados por comas"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Paquete</label>
                  <input
                    type="text"
                    value={this.state.package}
                    name="package"
                    className="form-control"
                    placeholder="Indica el paquete asociado"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            ) : null}
            {this.state.current === 3 ? (
              <div className="container">
                <div className="row">
                  <div className="row col-6">
                    <div className="form-group col-6">
                      <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeState} value={this.state.activeState}>
                        {this.props.country
                          .filter((state) => state.active === true)
                          .map((state, index) => (
                            <option value={state.state} key={state._id}>
                              {state.state}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group col-6">
                      <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeCity} value={this.state.city}>
                        {this.props.country.length > 0
                          ? this.props.country
                              .filter((state) => state.state === this.state.activeState)[0]
                              .city.filter((city) => city.active === true)
                              .map((city, index) => (
                                <option value={city.city} key={`${city.city._id}-${index}`}>
                                  {city.city}
                                </option>
                              ))
                          : null}
                      </select>
                    </div>
                    <div className="form-group col-12">
                      <label>Centro médico</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={this.handleInputHospitalChange}
                        name="hospital"
                        value={this.state.hospital}
                      >
                        {this.state.hospitals.length <= 0 ? (
                          <option disabled value="">
                            No hay hospitales en {this.state.city}
                          </option>
                        ) : null}
                        {this.state.hospitals.map((hospital, index) => (
                          <option value={hospital._id} key={hospital._id}>
                            {hospital.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-12">
                      <label>Dirección</label>
                      <input
                        type="text"
                        value={this.state.direction}
                        name="direction"
                        className="form-control"
                        placeholder="Indica la dirección del consultorio"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary"
                        onClick={this.addToConsultingRoom}
                        style={{
                          width: '100%',
                          margin: '0',
                          marginBottom: '10px',
                        }}
                        disabled={this.state.schedules.length <= 0}
                      >
                        Agregar consultorio
                      </button>
                    </div>
                  </div>
                  <div className="col-6">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: '10px',
                              width: '100%',
                            }}
                          >
                            Horarios
                          </th>
                          <th style={{ padding: '10px' }}>
                            <button
                              className="btn btn-primary"
                              onClick={this.handleShow}
                              style={{
                                margin: '0',
                                padding: '5px 10px',
                              }}
                              disabled={this.state.schedules.length >= 3 || this.state.hospital === ''}
                            >
                              Agregar horario
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.schedules.map((item, index) => (
                          <tr>
                            <td>
                              <h6 className="mb-1">{this.showDaysExtend(item.days)}</h6>
                              <p className="m-0">{item.time}</p>
                            </td>
                            <td
                              style={{
                                width: '60px',
                                cursor: 'pointer',
                                textAlign: 'end',
                              }}
                            >
                              <i className="feather icon-trash-2" onClick={() => this.deleteshcedule(index)}></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Nombre del centro médico</th>
                      <th>Ubicación</th>
                      <th>Horario</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.consulting_room.map((item, index) => (
                      <tr>
                        <td>{this.getNameById(item.hospital)}</td>
                        <td>{item.direction}</td>
                        <td>
                          {item.schedules.map((data) => (
                            <>
                              {' '}
                              <p>{data.days}</p> <p>{data.time}</p>{' '}
                              <p>
                                Semana: <span className="text-uppercase">{this.showWeekType(data.week_type)}</span>
                              </p>
                            </>
                          ))}
                        </td>
                        <td style={{ width: '60px', cursor: 'pointer' }}>
                          <i className="feather icon-trash-2" onClick={() => this.deleteToConsultingRomm(index)}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {this.state.consulting_room.length <= 0 ? <p style={{ textAlign: 'center' }}>No hay consultorios agregados</p> : null}
              </div>
            ) : null}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '15px 0 ',
            }}
          >
            <button className="btn btn-secundary" style={{ margin: ' 0 15px' }} onClick={() => this.close(false)}>
              Cancelar
            </button>
            <button className="btn btn-primary" style={{ margin: ' 0 15px' }} onClick={this.saveDoctor} disabled={this.state.inSave}>
              Guardar doctor
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialities: state.specialities,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorForm);
