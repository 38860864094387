import global from './global'
import * as axios from 'axios'

const URL = global.URL
const headers = {
    'Authorization': localStorage.getItem('token')
}

async function ListHospitals(city) {
    try {
        return await axios.get(URL + '/api/hospital/city?city=' + city).then(response => {
            return response.data
        })
    } catch (error) {
        console.log(error)
    }
}
async function SaveHospital(hospital) {
    try {
        return await axios.post(URL + '/api/hospital', hospital, {
            headers
        })
    } catch (error) {
        console.log(error)
    }
}

async function DeleteHospital(id) {
    try {
        return await axios.delete(URL + '/api/hospital?id=' + id, {
            headers
        })
    } catch (error) {
        console.log(error)
    }
}
async function UpdateHospital(hospital, id) {
    try {
        return await axios.put(URL + '/api/hospital?id=' + id, hospital, {
            headers
        })
    } catch (error) {
        console.log(error)
    }
}
export default {
    ListHospitals,
    SaveHospital,
    DeleteHospital,
    UpdateHospital
}