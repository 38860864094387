import React, { Component } from 'react';
import DayWeeksSelector from '../doctors/weekdays';
import { Modal } from 'react-bootstrap';

import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Steps, Select, TimePicker, Tag, Checkbox } from 'antd';
import moment from 'moment';
import global from '../../../services/global';
import businessService from '../../../services/business.service';

const { Step } = Steps;
const stepStyle = {
  marginBottom: 60,
  boxShadow: '0px -1px 0 0 #e8e8e8 inset',
};

class BusinessForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      /*  */
      name: '',
      phone: '',
      phone_consult: '',
      profilepic: '',
      city: 'Puerto Ordaz',
      email: '',
      direction: '',
      whatsapp: '',
      /*  */
      type: '1',
      services: '',
      schedules: [],
      package: '',
      /*  */
      web: '',
      facebook: '',
      facebooknickname: '',
      instagram: '',
      instagramnickname: '',
      twitter: '',
      twitternickname: '',
      youtube: '',
      youtubenickname: '',
      linkedin: '',
      linkedinnickname: '',
      tiktok: '',
      ticktoknickname: '',
      /*  */
      days: 'Lun,Mar,Mie,Jue,Vie,Sab,Dom',
      tagstime: [],
      index: 0,
      time_start: '08:00 am',
      time_end: '12:00 pm',
      show: false,
      preview: null,
      inSave: false,
    };
  }
  componentDidMount() {
    if (this.props.update) {
      this.setState({
        name: this.props.update.name,
        city: this.props.update.city,
        index: this.props.country.map((a) => a.state).indexOf(this.props.update.state),
        phone: this.props.update.phone,
        phone_consult: this.props.update.phone_consult,
        profilepic: this.props.update.profilepic,
        src: this.props.update.profilepic,
        type: this.props.update.type,
        direction: this.props.update.direction,
        package: this.props.update.package,
        services: this.props.update.services,
        whatsapp: this.props.update.whatsapp,
        web: this.props.update.web,
        schedules: this.props.update.schedules,
        facebook: this.props.update.facebook.url,
        facebooknickname: this.props.update.facebook.nickname,
        instagram: this.props.update.instagram.url,
        instagramnickname: this.props.update.instagram.nickname,
        twitter: this.props.update.twitter.url,
        twitternickname: this.props.update.twitter.nickname,
        youtube: this.props.update.youtube.url,
        youtubenickname: this.props.update.youtube.nickname,
        linkedin: this.props.update.linkedin.url,
        linkedinnickname: this.props.update.linkedin.nickname,
        tiktok: this.props.update.tiktok.url,
        tiktoknickname: this.props.update.tiktok.nickname,
        email: this.props.update.email,
      });
    }
  }
  changeSteps = (current) => {
    this.setState({ current });
  };
  changeState = (event) => {
    this.setState({
      index: event.target.value,
      city: this.props.country[event.target.value].city[0],
    });
  };
  changeCity = (event) => {
    this.setState({ city: event.target.value });
  };
  agregateTagTime = () => {
    if (this.state.tagstime.length < 3) {
      var times = this.state.tagstime;
      times.push(this.state.time_start + ' - ' + this.state.time_end);
      this.setState({ tagstime: times });
    }
  };
  handleInputTimeChange = (time, timeString) => {
    var value = timeString;
    this.setState({ time_start: value });
  };
  handleInputTimeEndChange = (time, timeString) => {
    var value = timeString;
    this.setState({ time_end: value });
  };
  changeDaySelects = (e, index) => {
    this.setState({ days: e });
  };
  agregateSchedules = () => {
    var timeString = this.state.tagstime.toString();
    var schedule = {
      days: this.state.cita ? '' : this.state.days,
      time: this.state.cita ? 'Previa cita' : timeString,
    };
    var schedules = this.state.schedules;
    schedules.push(schedule);
    this.setState({ schedules });
    this.handleClose();
  };
  handleClose = () => {
    this.setState({
      show: false,
      cita: false,
      tagstime: [],
      time_start: '08:00 am',
      time_end: '12:00 pm',
    });
  };
  handleShow = () => {
    if (this.state.schedules.length < 3) {
      this.setState({ show: true });
    } else {
    }
  };
  deleteshcedule = (id) => {
    var schedules = this.state.schedules;
    schedules.splice(id, 1);
    this.setState({ schedules });
  };
  handleInputChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  };
  validateData = () => {
    if (this.state.profilepic !== null && this.state.profilepic !== '' && this.state.name !== '') return true;
    else return false;
  };
  onClose = () => {
    this.setState({ preview: null });
  };

  onCrop = (preview) => {
    this.setState({ preview });
  };
  dayExtend = (day) => {
    switch (day) {
      case 'Lun':
        return 'Lunes';
      case 'Mar':
        return 'Martes';
      case 'Mie':
        return 'Miércoles';
      case 'Jue':
        return 'Jueves';
      case 'Vie':
        return 'Viernes';
      case 'Sab':
        return 'Sábado';
      case 'Dom':
        return 'Domingo';
      default:
        return '';
    }
  };
  showDaysExtend = (days) => {
    days = days.split(',');
    if (days[0] === '') {
      days.splice(0, 1);
      days = days.toString();
    }
    switch (days.toString()) {
      case 'Lun,Mar,Mie,Jue,Vie,Sab,Dom':
        return 'Lunes a Domingo';
      case 'Lun,Mar,Mie,Jue,Vie,Sab':
        return 'Lunes a Sábado';
      case 'Lun,Mar,Mie,Jue,Vie':
        return 'Lunes a Viernes';
      case 'Lun,Mar,Mie,Jue':
        return 'Lunes a Jueves';
      case 'Lun,Mar,Mie':
        return 'Lunes a Miércoles';
      case 'Lun,Mar':
        return 'Lunes y Martes';
      case 'Mar,Mie':
        return 'Martes y Miércoles';
      case 'Mar,Mie,Jue':
        return 'Martes a Jueves';
      case 'Mar,Mie,Jue,Vie':
        return 'Martes a Viernes';
      case 'Mar,Mie,Jue,Vie,Sab':
        return 'Martes a Sábado';
      case 'Mar,Mie,Jue,Vie,Sab,Dom':
        return 'Martes a Domingo';
      case 'Mie,Jue':
        return 'Miércoles y Jueves';
      case 'Mie,Jue,Vie':
        return 'Miércoles a Viernes';
      case 'Mie,Jue,Vie,Sab':
        return 'Miércoles a Sábado';
      case 'Mie,Jue,Vie,Sab,Dom':
        return 'Miércoles a Domingo';
      case 'Jue,Vie':
        return 'Jueves a Viernes';
      case 'Jue,Vie,Sab':
        return 'Jueves a Sábado';
      case 'Jue,Vie,Sab,Dom':
        return 'Jueves a Domingo';
      case 'Vie,Sab':
        return 'Viernes y Sábado';
      case 'Vie,Sab,Dom':
        return 'Viernes a Domingo';
      default: {
        let d = days.toString();
        const daysarray = d.split(',');
        var daytext = '';
        for (let i = 0; i < daysarray.length; i++) {
          if (i === daysarray.length - 1 && i > 0) {
            daytext += ' y ';
          } else if (i !== 0) daytext += ', ';
          daytext += this.dayExtend(daysarray[i]);
        }
        return daytext;
      }
    }
  };
  saveBusiness = () => {
    let data = {
      name: this.state.name,
      phone: this.state.phone,
      phone_consult: this.state.phone_consult,
      type: this.state.type,
      profilepic: this.state.profilepic,
      whatsapp: this.state.whatsapp,
      email: this.state.email,
      web: this.state.web,
      city: this.state.city,
      state: this.props.country[this.state.index].state,
      direction: this.state.direction,
      services: this.state.services,
      package: this.state.package,
      schedules: this.state.schedules,
      facebook: {
        url: this.state.facebook,
        nickname: this.state.facebooknickname,
      },
      instagram: {
        url: this.state.instagram,
        nickname: this.state.instagramnickname,
      },
      twitter: {
        url: this.state.twitter,
        nickname: this.state.twitternickname,
      },
      youtube: {
        url: this.state.youtube,
        nickname: this.state.youtubenickname,
      },
      linkedin: {
        url: this.state.linkedin,
        nickname: this.state.linkedinnickname,
      },
      tiktok: {
        url: this.state.tiktok,
        nickname: this.state.tiktoknickname,
      },
    };
    this.setState({ inSave: true });
    if (this.validateData()) {
      if (!this.props.update) {
        businessService.Save(data).then((res) => {
          this.setState({ inSave: false });
          Swal.fire({
            type: 'success',
            title: 'Consultorio creado satisfactoriamente',
            showConfirmButton: false,
            timer: 1500,
          });
        });
      } else {
        businessService.Update(data, this.props.update._id).then((res) => {
          this.setState({ inSave: false });
          Swal.fire({
            type: 'success',
            title: 'Consultorio actualizado satisfactoriamente',
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
      this.close(false);
    } else {
      this.setState({ inSave: false });
      Swal.fire({
        type: 'warning',
        title: 'Debe rellenar todos los campos',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  handleInputFileChange = (e) => {
    const formData = new FormData();
    formData.append('imagen', e.target.files[0]);
    businessService.UploadPic(formData).then((res) => {
      if (res.data) {
        this.setState({
          profilepic: global.URL + '/api/file?file=' + res.data.imagen,
        });
      }
    });
  };
  clearState = () => {
    this.setState({
      current: 0,
      /*  */
      name: '',
      phone: '',
      phone_consult: '',
      profilepic: '',
      city: 'Puerto Ordaz',
      state: 'Bolívar',
      email: '',
      direction: '',
      whatsapp: '',
      /*  */
      type: '1',
      services: '',
      schedules: [],
      package: '',
      /*  */
      web: '',
      facebook: '',
      facebooknickname: '',
      instagram: '',
      instagramnickname: '',
      twitter: '',
      twitternickname: '',
      youtube: '',
      youtubenickname: '',
      linkedin: '',
      linkedinnickname: '',
      tiktok: '',
      ticktoknickname: '',
      /*  */
      days: 'Lun,Mar,Mie,Jue,Vie,Sab,Dom',
      tagstime: [],
      index: 0,
      time_start: '08:00 am',
      time_end: '12:00 pm',
      show: false,
      preview: null,
      src: null,
    });
  };
  close = (e) => {
    this.clearState();
    this.props.onClose(e);
  };

  render() {
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose} size="lg">
          <Modal.Body>
            <div className="container">
              <div>
                <label>Dias de trabajo</label>
                <DayWeeksSelector value={this.state.days} name="days" changeDaySelect={(e) => this.changeDaySelects(e, 0)} />
              </div>
              <div>
                <Checkbox className="col-12" defaultValue={this.state.cita} onChange={() => this.setState({ cita: !this.state.cita })}>
                  Previa cita
                </Checkbox>
              </div>
              <div className="row">
                {!this.state.cita ? (
                  <>
                    <div className="col-12">
                      {this.state.tagstime.map((tg, index) => (
                        <Tag key={index} color="#0a4785" closable>
                          {tg}
                        </Tag>
                      ))}
                    </div>
                    <div className="form-group col-4" style={{ display: 'grid' }}>
                      <label>Hora de inicio</label>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        name="time_start"
                        minuteStep={15}
                        value={moment(this.state.time_start, 'HH:mm a')}
                        onChange={this.handleInputTimeChange}
                      />
                    </div>
                    <div className="form-group col-4" style={{ display: 'grid' }}>
                      <label>Hora final</label>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        name="time_end"
                        minuteStep={15}
                        value={moment(this.state.time_end, 'HH:mm a')}
                        onChange={this.handleInputTimeEndChange}
                      />
                    </div>
                    <div
                      className="col-4"
                      style={{
                        display: 'flex',
                        marginBottom: '1rem',
                        alignItems: 'flex-end',
                      }}
                    >
                      <button className="btn btn-info" onClick={this.agregateTagTime} style={{ width: '100%', margin: '0' }}>
                        Agregar hora
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secundary" onClick={this.handleClose}>
              Cancelar
            </button>
            <button className="btn btn-primary" onClick={this.agregateSchedules} disabled={!this.state.cita && this.state.tagstime.length <= 0}>
              Guardar
            </button>
          </Modal.Footer>
        </Modal>
        <div className="card" style={{ boxShadow: '0 1px 20px 0 rgba(69, 90, 100, 0.4)' }}>
          <div className="card-block px-0 py-3">
            <Steps type="navigation" size="small" current={this.state.current} onChange={this.changeSteps} style={stepStyle}>
              <Step status={this.state.current === 0 ? 'process' : 'wait'} title="Datos básicos" />
              <Step status={this.state.current === 1 ? 'process' : 'wait'} title="Redes sociales" />
              <Step status={this.state.current === 2 ? 'process' : 'wait'} title="Servicios y horarios" />
            </Steps>
            {this.state.current === 0 ? (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {/*  <Avatar
                      height={200}
                      width={200}
                      onCrop={this.onCrop}
                      onClose={this.onClose}
                      src={this.state.src}
                      label="Imagen de perfil"
                      imageWidth={200}
                      imageHeight={200}
                    />
                    {this.state.preview !== '' ?<img src={this.state.preview} alt="img" style={{width:'80px',margin:'20px'}}></img>:null} */}
                    <input type="file" className="form-control" onChange={this.handleInputFileChange} />
                    {this.state.profilepic !== '' ? (
                      <img src={this.state.profilepic} alt="profilepic" style={{ width: '20%' }}></img>
                    ) : (
                      <div>No se ha subido una foto</div>
                    )}
                  </div>
                  <div className="row col-12">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Nombre</label>
                        <input
                          type="text"
                          name="name"
                          value={this.state.name}
                          className="form-control"
                          placeholder="Indica el nombre del consultorio"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>Teléfono</label>
                        <input
                          type="text"
                          value={this.state.phone}
                          name="phone"
                          className="form-control"
                          placeholder="Indica el número telefonico"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>Número consultorio</label>
                        <input
                          type="text"
                          value={this.state.phone_consult}
                          name="phone_consult"
                          className="form-control"
                          placeholder="Indica el número telefonico"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>Correo electrónico</label>
                        <input
                          type="text"
                          value={this.state.email}
                          name="email"
                          className="form-control"
                          placeholder="Indica el correo electrónico"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <label>Dirección</label>
                      <div className="row">
                        <div className="form-group col-6">
                          <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeState} value={this.state.index}>
                            {this.props.country.map((state, index) => (
                              <option value={index} key={state._id}>
                                {state.state}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-6">
                          <select className="form-control" id="exampleFormControlSelect1" onChange={this.changeCity} value={this.state.city.city}>
                            {this.props.country.length > 0
                              ? this.props.country[this.state.index].city.map((city, index) => (
                                  <option value={city.city} key={index}>
                                    {city.city}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                        <div className="form-group col-12">
                          <input
                            type="text"
                            value={this.state.direction}
                            name="direction"
                            className="form-control"
                            placeholder="Indica la dirección del consultorio"
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="form-group col-12">
                          <label>Tipo de consultorio</label>
                          <select className="form-control" onChange={(e) => this.setState({ type: e.target.value })} value={this.state.type}>
                            <option value={'1'}>Consultorio odontológico</option>
                            <option value={'2'}>Consultorio oftalmológico</option>
                            <option value={'3'}>Laboratorio</option>
                            <option value={'4'}>Spa</option>
                            <option value={'5'}>Farmacia</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.current === 1 ? (
              <div className="container">
                <div className="row">
                  <div className="form-group col-6">
                    <label>Página web</label>
                    <input
                      type="text"
                      value={this.state.web}
                      name="web"
                      className="form-control"
                      placeholder="Indica la página web"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Whatsapp</label>
                    <input
                      type="text"
                      value={this.state.whatsapp}
                      name="whatsapp"
                      className="form-control"
                      placeholder="Indica el número de whatsapp"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Facebook</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.facebooknickname}
                          name="facebooknickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.facebook}
                          name="facebook"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Twitter</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.twitternickname}
                          name="twitternickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.twitter}
                          name="twitter"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Instagram</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.instagramnickname}
                          name="instagramnickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.instagram}
                          name="instagram"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Youtube</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.youtubenickname}
                          name="youtubenickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.youtube}
                          name="youtube"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Instagram 2</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.linkedinnickname}
                          name="linkedinnickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.linkedin}
                          name="linkedin"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>TikTok</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.tiktoknickname}
                          name="tiktoknickname"
                          className="form-control"
                          placeholder="Nombre de usuario"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          value={this.state.tiktok}
                          name="tiktok"
                          className="form-control"
                          placeholder="Url del perfil"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.current === 2 ? (
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Servicios</label>
                      <input
                        type="text"
                        value={this.state.services}
                        name="services"
                        className="form-control"
                        placeholder="Indica los servicios que ofrece separados por comas"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Paquete</label>
                      <input
                        type="text"
                        value={this.state.package}
                        name="package"
                        className="form-control"
                        placeholder="Indica el paquete asociado"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: '10px',
                              width: '100%',
                            }}
                          >
                            Horarios
                          </th>
                          <th style={{ padding: '10px' }}>
                            <button
                              className="btn btn-primary"
                              onClick={this.handleShow}
                              style={{
                                margin: '0',
                                padding: '5px 10px',
                              }}
                              disabled={this.state.schedules.length >= 3}
                            >
                              Agregar horario
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.schedules.map((item, index) => (
                          <tr>
                            <td>
                              <h6 className="mb-1">{this.showDaysExtend(item.days)}</h6>
                              <p className="m-0">{item.time}</p>
                            </td>
                            <td
                              style={{
                                width: '60px',
                                cursor: 'pointer',
                                textAlign: 'end',
                              }}
                            >
                              <i className="feather icon-trash-2" onClick={() => this.deleteshcedule(index)}></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '15px 0 ',
            }}
          >
            <button className="btn btn-secundary" style={{ margin: ' 0 15px' }} onClick={() => this.close(false)}>
              Cancelar
            </button>
            <button className="btn btn-primary" disabled={this.state.inSave} style={{ margin: ' 0 15px' }} onClick={this.saveBusiness}>
              Guardar consultorio
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialities: state.specialities,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessForm);
