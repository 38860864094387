import global from './global';
import * as axios from 'axios';

const URL = global.URL;
const headers = {
  Authorization: localStorage.getItem('token')
};

async function Save(data) {
  try {
    return await axios.post(URL + '/api/business', data, {
      headers
    });
  } catch (error) {
    console.log(error);
  }
}
async function Delete(id) {
  try {
    return await axios.delete(URL + '/api/business?id=' + id, {
      headers
    });
  } catch (error) {
    console.log(error);
  }
}
async function Update(data, id) {
  try {
    return await axios.put(URL + '/api/business?id=' + id, data, {
      headers
    });
  } catch (error) {
    console.log(error);
  }
}
async function GetAll() {
  try {
    return await axios.get(URL + '/api/business/all', {
      headers
    });
  } catch (error) {
    console.log(error);
  }
}
async function GetAllByType(city, type) {
  try {
    let params = {
      city,
      type
    }
    return await axios.get(URL + '/api/business/type', {
      headers,
      params
    });
  } catch (error) {
    console.log(error);
  }
}
async function UploadPic(file) {
  try {
    return await axios.post(URL + '/api/upload/single', file, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  } catch (error) {
    console.log(error);
  }
}


export default {
  Delete,
  UploadPic,
  Save,
  Update,
  GetAll,
  GetAllByType
};